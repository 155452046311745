<template>
  <div class="home" :class="{ 'is-calendar': getCalendarMode() }">
    <DisabledSiteModal v-if="getMaintenanceSetting().enabled"></DisabledSiteModal>

    <asModalDialog ref="calendarsDisabled" heading-text="Kampanja on päättynyt" :show-ok="false" :show-cancel="false">
      <p>Kalenteritilausten vastaanotto on päättynyt vuoden 2024 osalta.</p>
    </asModalDialog>

    <section class="hero">
      <SectionHeader mode="home" />
      <div class="is-full pt-0" v-if="!getCalendarMode()">
        <img class="is-fullwidth" :src="`${publicPath}imgs/TopBanner.jpg`" alt="Esittelykuvat personoiduista Domino ja Fazer sininen pakkauksista."/>
      </div>
      <div class="is-full pt-0" v-else>
        <img
          class="is-fullwidth"
          :src="`${publicPath}imgs/banner-mockup-calendar.png`"
          alt="Saa joulun maistumaan ja Kolme esittelykuvaa oma joulukalenterista eri kuvilla."
        />
      </div>
      <div class="hero-body is-fullheight">
        <div class="container">
          <div class="columns mb-6">
            <div class="column is-10 is-offset-1 is-paddingless">
              <h1 class="mb-4 home-top-text">{{ getListLandingTitles[0]['title'] }}</h1>
              <p class="home-top-text" style="white-space: pre-line">
                {{ getListLandingTexts[0]['text'] }}
              </p>
              <p class="mt-5 home-top-text" v-if="getCalendarMode()">Aloita kalenterin suunnittelu valitsemalla tuote alta.</p>
              <p class="mt-5 home-top-text" v-else>Aloita valitsemalla tuote alla olevista vaihtoehdoista</p>
            </div>
          </div>
          <!-- Calendar view -->
          <template v-if="getCalendarMode()">
            <div class="columns mb-6 is-hidden-mobile box-wrapper-mobile">
              <div class="column is-5 is-offset-1 front-page-box-column">
                <div class="front-page-box">
                  <h3 style="color: #ffffff">Tilaa joulukalenteri omalla kuvalla</h3>
                  <p>
                    Joulukalenteri Fazerin suklaakonvehdeilla ja omalla valokuvallasi. Suunnittele kalenteri palvelussa
                    ja tilaa itsellesi tai lähimmäisellesi. Voit lisätä kalenteriin halutessasi jouluisen kehyksen.
                    Kalenteri toimitetaan postipakettina lähimpään noutopisteeseen.
                  </p>
                  <p>
                    Kalenterin hinta 25,90 € / kpl
                  </p>
                  <button
                    v-if="!getMaintenanceSetting().enabled"
                    class="upload-button upload-button-small-box button is-primary"
                    @click="goToEditor('calendar')"
                  >
                    <span>Tilaa</span>
                  </button>
                </div>
              </div>
              <div class="column is-5 is-paddingless">
                <figure class="image">
                  <img src="../assets/img/calendar_landing@2x.png" />
                </figure>
              </div>
            </div>


            <div class="columns is-hidden-tablet">
              <div class="column is-5 is-paddingless">
                <figure class="image">
                  <img src="../assets/img/calendar_landing@2x.png" alt="Joulukalenteri kuva"/>
                </figure>
              </div>
              <div class="column is-5 is-offset-1 front-page-box-column">
                <div class="front-page-box">
                  <h3>Tilaa joulukalenteri omalla kuvalla</h3>
                  <p>
                    Joulukalenteri Fazerin suklaakonvehdeilla ja omalla valokuvallasi. Suunnittele kalenteri palvelussa
                    ja tilaa itsellesi tai lähimmäisellesi. Voit lisätä kalenteriin halutessasi jouluisen kehyksen.
                    Kalenteri toimitetaan postipakettina lähimpään noutopisteeseen.
                  </p>
                  <p>Kalenterin hinta 25,90 € / kpl</p>
                  <button
                    v-if="!getMaintenanceSetting().enabled"
                    class="upload-button upload-button-small-box button is-primary"
                    @click="goToEditor('calendar')"
                  >
                    <span>Tilaa</span>
                  </button>
                </div>
              </div>
            </div>


            <div class="columns mt-6">
              <div class="column is-10 is-offset-1 has-blue-border">
                <h3 class="has-text-left">Teethän tilauksesi viimeistään 12.11., jotta se ehtii perille ennen joulukuun alkua.</h3>
              </div>
            </div>
            <div class="columns bottom-landing pb-2 mb-0">
              <div class="column is-10 is-offset-1">
                <h2 class="mt-6 mb-4">Palvelun kuvaus lyhyesti</h2>
                <p>
                  Palvelussa voit personoida oman Fazer-suklaajoulukalenterin omalla valokuvallasi. Toimitusaika on noin
                  kaksi viikkoa. Toimitamme enintään 20 kalenteria/tilaus. Maksutapana on yleisimmät verkkomaksutavat,
                  kuten pankkimaksu ja luottokortti. Tarkistamme kalenterin kuvamateriaalin, jotta se on hyvien tapojen
                  mukainen. Lue lisää palvelun sopimus- ja toimitusehdoista <router-link :to="{ name: 'rules' }">täältä</router-link>.
                </p>
                <p>
                  Fazer Oma kalenteri -palvelu on itsenäinen palvelu. Mikäli haluat tilata muita Fazerin tuotteita,
                  voit asioida <a href="https://fi.fazer.com/" target="_blank">Fazer Store -kuluttajaverkkokaupassamme.</a>
                </p>
                <h2 class="mt-6 mb-4">Palvelun hinta ja toimitus</h2>
                <p>
                  Palvelun hinta on 25,90 € / kalenteri + toimitusmaksu 4,90 €. Toimitamme tuotteet Manner-Suomeen.
                  Tuotteet toimitetaan postipakettina.
                </p>
                <h2 class="mt-6 mb-4">Kuinka tehdä täydellinen kalenteri?</h2>
                <p>
                  Oma suklaakalenteri on persoonallinen ja herkullinen tapa muistaa läheisiä tai piristää omaa joulun
                  odotusta. Kuva voi olla muisto itselle tärkeästä hetkestä, lemmikistä tai läheisistä – vain
                  mielikuvitus on rajana! Ja mikä parasta, tämä kalenteri ja tärkeä kuva piristää päivittäin matkalla
                  kohti joulua. Kun otat kuvan suunnittelussa huomioon muutaman asian, niin et voi epäonnistua. Katso
                  vastaukset <router-link :to="{ name: 'faq' }">usein kysyttyihin kysymyksiin</router-link>.
                </p>
              </div>
            </div>
            <div class="column is-10 is-offset-1 mb-2">
              <h2 class="is-center">Vain mielikuvituksesi on rajana</h2>
            </div>
            <div class="bottom-mosaic mb-6">
              <div class="columns">
                <div class="column is-10 is-offset-1">
                  <div class="columns">
                    <div class="column is-4">
                      <img src="../assets/img/calendar1.png" />
                    </div>
                    <div class="column is-4">
                      <img src="../assets/img/calendar2.png" />
                    </div>
                    <div class="column is-4">
                      <img src="../assets/img/calendar3.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <!-- Non Calendar View -->
          <template v-else>
            <div class="columns mb-6 is-hidden-tablet">
              <div class="column is-5 is-paddingless">
                <figure class="image">
                  <img src="../assets/img/calendar_landing@2x.png" />
                </figure>
              </div>
              <div class="column is-5 is-offset-1 front-page-box-column">
                <div class="front-page-box">
                  <h3>Tilaa joulukalenteri omalla kuvalla</h3>
                  <p>
                    Suunnittele suklaajoulukalenteri omalla kuvalla. Lataa kuva ja näet heti, miltä kalenteri näyttää.
                    Voit lisätä kalenteriin halutessasi jouluisen kehyksen. Kalenterin koko 26,8 x 38,8 x 1,8 cm.
                    Minimitilaus 20 kalenteria.
                  </p>
                  <p>Hinta 12,53-25,90 € / kpl riippuen tilausmäärästä</p>
                  <button
                    v-if="!getMaintenanceSetting().enabled"
                    class="upload-button upload-button-small-box button is-primary"
                    @click="goToEditor('calendar-b2b')"
                  >
                    <span>Tilaa</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="columns mb-6 is-hidden-tablet">
              <div class="column is-5 is-paddingless">
                <figure class="image">
                  <img :src="`${publicPath}imgs/fazer-landing-picture.png`" alt="Karl Fazer -konvehtirasia kuva"/>
                </figure>
              </div>
              <div class="column is-5 is-offset-1 front-page-box-column">
                <div class="front-page-box">
                  <h3>Karl Fazer -konvehtirasia omalla tekstillä</h3>
                  <p>
                    Minimitilaus on 20 pakettia, joista vaikka jokaisessa voi olla eri teksti. Tyylikäs pakkaus
                    persoonallisella viestillä.
                  </p>
                  <p>Hinta 5,50-13 €/kpl riippuen tilausmäärästä.</p>
                  <button
                    v-if="!getMaintenanceSetting().enabled"
                    class="upload-button upload-button-small-box button is-primary"
                    @click="goToEditor('fazer')"
                    tabindex="0" role="button"
                    aria-label="Tilaa karl fazer -konvehtirasia paketti"
                  >
                    <span>Tilaa</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="columns mb-6 is-hidden-tablet">
              <div class="column is-5 is-paddingless">
                <figure class="image">
                  <img :src="`${publicPath}imgs/domino-landing-picture.png`" alt="Domino-paketit kuvat"/>
                </figure>
              </div>
              <div class="column is-5 is-offset-1 front-page-box-column">
                <div class="front-page-box">
                  <h3>Domino-paketit omalla kuvalla ja logolla</h3>
                  <p>
                    Minimitilaus on 24 pakettia, joista vaikka jokaisessa voi olla eri kuva. Sopii myös varainkeruuseen.
                  </p>
                  <p>Hinta 5-8 €/kpl riippuen tilausmäärästä.</p>
                  <button
                    v-if="!getMaintenanceSetting().enabled"
                    class="upload-button upload-button-small-box button is-primary"
                    @click="goToEditor('domino')"
                    tabindex="0" role="button"
                    aria-label="Tilaa domino paketti"
                  >
                    <span>Tilaa</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="columns mb-6 is-hidden-tablet">
              <div class="column is-5 is-paddingless">
                <figure class="image">
                  <img :src="`${publicPath}imgs/Custom-Candy-Box.jpg`" alt="Karkkipaketti kuva"/>
                </figure>
              </div>
              <div class="column is-5 is-offset-1 front-page-box-column">
                <div class="front-page-box">
                  <h3>Karkkipaketti omalla logolla ja värillä</h3>
                  <p>
                    Personoi pakettisi yrityksen omalla logolla ja valitsemallasi värillä. Valitse karkkipussi
                    vaihtoehdoista. Minimitilaus 50 pakettia, joista vaikka jokaisessa voi olla eri teksti. Mukaan tulee
                    kortti, johon saat pidemmän tekstin. Toimitus mahdollista myös postilaatikkoon.
                  </p>
                  <p>Hinta 4,40-9,90 €/kpl riippuen tilausmäärästä.</p>
                  <button
                    v-if="!getMaintenanceSetting().enabled"
                    class="upload-button button is-primary"
                    @click="goToEditor('custom-candy-box')"
                    tabindex="0" role="button"
                    aria-label="Tilaa karkkipaketti"
                  >
                    <span>Tilaa</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="columns mb-6 is-hidden-tablet">
              <div class="column is-5 is-paddingless">
                <figure class="image">
                  <img :src="`${publicPath}imgs/Custom-Fazer-Minun-Marianne-693x430.jpg`" alt="Marianne-paketti kuva"/>
                </figure>
              </div>
              <div class="column is-5 is-offset-1 front-page-box-column">
                <div class="front-page-box">
                  <h3>Marianne-paketti omalla viestillä</h3>
                  <p>
                    Minimitilaus 50 pakettia, joista vaikka jokaisessa voi olla eri teksti. Mukaan tulee kortti, johon
                    saat pidemmän tekstin. Toimitus mahdollista myös postilaatikkoon.
                  </p>
                  <p>Hinta 4,40-9,90 €/kpl riippuen tilausmäärästä.</p>
                  <button
                    v-if="!getMaintenanceSetting().enabled"
                    class="upload-button upload-button-small-box button is-primary"
                    @click="goToEditor('marianne')"
                    tabindex="0" role="button"
                    aria-label="Tilaa marianne paketti"
                  >
                    <span>Tilaa</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="columns is-hidden-tablet">
              <div class="column is-5 is-paddingless">
                <figure class="image">
                  <img :src="`${publicPath}imgs/Custom-Fazer-Minun-Sininen-693x430.jpg`" alt="Karl Fazer -suklaalevy kuva"/>
                </figure>
              </div>
              <div class="column is-5 is-offset-1 front-page-box-column">
                <div class="front-page-box">
                  <h3>Karl Fazer -suklaalevy omalla viestillä</h3>
                  <p>
                    Suosittu persoonallinen maitosuklaalevy omalla viestillä. Takapuolelle saat pidemmän tekstin.
                    Toimitus mahdollista myös postilaatikkoon. Minimitilaus 50 kpl, joista vaikka jokaisessa voi olla
                    eri teksti *. Myös muita makuja saatavilla.
                  </p>
                  <p>Hinta 4,40-9,90 €/kpl riippuen tilausmäärästä.</p>
                  <button
                    v-if="!getMaintenanceSetting().enabled"
                    class="upload-button upload-button-small-box button is-primary"
                    @click="goToEditor('fazer-bar')"
                    tabindex="0" role="button"
                    aria-label="Tilaa suklaalevy"
                  >
                    <span>Tilaa</span>
                  </button>
                </div>
              </div>
            </div>

            <div class="columns mb-6 is-hidden-mobile box-wrapper-mobile">
              <div class="column is-5 is-offset-1 front-page-box-column">
                <div class="front-page-box">
                  <h3>Tilaa joulukalenteri omalla kuvalla</h3>
                  <p>
                    Suunnittele suklaajoulukalenteri omalla kuvalla. Lataa kuva ja näet heti, miltä kalenteri näyttää.
                    Voit lisätä kalenteriin halutessasi jouluisen kehyksen. Kalenterin koko 26,8 x 38,8 x 1,8 cm.
                    Minimitilaus 20 kalenteria.
                  </p>
                  <p>Hinta 12,53-25,90 € / kpl riippuen tilausmäärästä</p>
                  <button
                    v-if="!getMaintenanceSetting().enabled"
                    class="upload-button upload-button-small-box button is-primary"
                    @click="goToEditor('calendar-b2b')"
                  >
                    <span>Tilaa</span>
                  </button>
                </div>
              </div>
              <div class="column is-5 is-paddingless">
                <figure class="image">
                  <img src="../assets/img/calendar_landing@2x.png" />
                </figure>
              </div>
            </div>
            <div class="columns mb-6 is-hidden-mobile box-wrapper-mobile">
              <div class="column is-5 is-offset-1 front-page-box-column">
                <div class="front-page-box">
                  <h3>Karl Fazer -konvehtirasia omalla tekstillä</h3>
                  <p>
                    Minimitilaus on 20 pakettia, joista vaikka jokaisessa voi olla eri teksti. Tyylikäs pakkaus
                    persoonallisella viestillä.
                  </p>
                  <p>Hinta 5,50-13 €/kpl riippuen tilausmäärästä.</p>
                  <button
                    v-if="!getMaintenanceSetting().enabled"
                    class="upload-button upload-button-small-box button is-primary"
                    @click="goToEditor('fazer')"
                    tabindex="0" role="button"
                    aria-label="Tilaa karl fazer -konvehtirasia paketti"
                  >
                    <span>Tilaa</span>
                  </button>
                </div>
              </div>
              <div class="column is-5 is-paddingless">
                <figure class="image">
                  <img :src="`${publicPath}imgs/fazer-landing-picture.png`" alt="Karl Fazer -konvehtirasia kuva"/>
                </figure>
              </div>
            </div>
            <div class="columns mb-6 is-hidden-mobile box-wrapper-mobile">
              <div class="column is-5 is-offset-1 front-page-box-column">
                <div class="front-page-box">
                  <h3>Domino-paketit omalla kuvalla ja logolla</h3>
                  <p>
                    Minimitilaus on 24 pakettia, joista vaikka jokaisessa voi olla eri kuva. Sopii myös varainkeruuseen.
                  </p>
                  <p>Hinta 5-8 €/kpl riippuen tilausmäärästä.</p>
                  <button
                    v-if="!getMaintenanceSetting().enabled"
                    class="upload-button upload-button-small-box button is-primary"
                    @click="goToEditor('domino')"
                    tabindex="0" role="button"
                    aria-label="Tilaa domino paketti"
                  >
                    <span>Tilaa</span>
                  </button>
                </div>
              </div>
              <div class="column is-5 is-paddingless">
                <figure class="image">
                  <img :src="`${publicPath}imgs/domino-landing-picture.png`" alt="Domino pakettit kuvat" />
                </figure>
              </div>
            </div>
            <div class="columns mb-6 is-hidden-mobile box-wrapper-mobile">
              <div class="column is-5 is-offset-1 front-page-box-column">
                <div class="front-page-box">
                  <h3>Karkkipaketti omalla logolla ja värillä</h3>
                  <p>
                    Personoi pakettisi yrityksen omalla logolla ja valitsemallasi värillä. Valitse karkkipussi
                    vaihtoehdoista. Minimitilaus 50 pakettia, joista vaikka jokaisessa voi olla eri teksti. Mukaan tulee
                    kortti, johon saat pidemmän tekstin. Toimitus mahdollista myös postilaatikkoon.
                  </p>
                  <p>Hinta 4,40-9,90 €/kpl riippuen tilausmäärästä.</p>
                  <button
                    v-if="!getMaintenanceSetting().enabled"
                    class="upload-button button is-primary"
                    @click="goToEditor('custom-candy-box')"
                    tabindex="0" role="button"
                    aria-label="Tilaa karkkipaketti"
                  >
                    <span>Tilaa</span>
                  </button>
                </div>
              </div>
              <div class="column is-5 is-paddingless">
                <figure class="image">
                  <img :src="`${publicPath}imgs/Custom-Candy-Box.jpg`" alt="Karkkipaketti kuva"/>
                </figure>
              </div>
            </div>
            <div class="columns mb-6 is-hidden-mobile box-wrapper-mobile">
              <div class="column is-5 is-offset-1 front-page-box-column">
                <div class="front-page-box">
                  <h3>Marianne-paketti omalla viestillä</h3>
                  <p>
                    Minimitilaus 50 pakettia, joista vaikka jokaisessa voi olla eri teksti. Mukaan tulee kortti, johon
                    saat pidemmän tekstin. Toimitus mahdollista myös postilaatikkoon.
                  </p>
                  <p>Hinta 4,40-9,90 €/kpl riippuen tilausmäärästä.</p>
                  <button
                    v-if="!getMaintenanceSetting().enabled"
                    class="upload-button upload-button-small-box button is-primary"
                    @click="goToEditor('marianne')"
                    tabindex="0" role="button"
                    aria-label="Tilaa marianne paketti"
                  >
                    <span>Tilaa</span>
                  </button>
                </div>
              </div>
              <div class="column is-5 is-paddingless">
                <figure class="image">
                  <img :src="`${publicPath}imgs/Custom-Fazer-Minun-Marianne-693x430.jpg`" alt="Marianne paketti kuva" />
                </figure>
              </div>
            </div>
            <div class="columns mb-6 is-hidden-mobile box-wrapper-mobile">
              <div class="column is-5 is-offset-1 front-page-box-column">
                <div class="front-page-box">
                  <h3>Karl Fazer -suklaalevy omalla viestillä</h3>
                  <p>
                    Suosittu persoonallinen maitosuklaalevy omalla viestillä. Takapuolelle saat pidemmän tekstin.
                    Toimitus mahdollista myös postilaatikkoon. Minimitilaus 50 kpl, joista vaikka jokaisessa voi olla
                    eri teksti *. Myös muita makuja saatavilla.
                  </p>
                  <p>Hinta 4,40-9,90 €/kpl riippuen tilausmäärästä.</p>
                  <button
                    v-if="!getMaintenanceSetting().enabled"
                    class="upload-button upload-button-small-box button is-primary"
                    @click="goToEditor('fazer-bar')"
                    tabindex="0" role="button"
                    aria-label="Tilaa suklaalevy"
                  >
                    <span>Tilaa</span>
                  </button>
                </div>
              </div>
              <div class="column is-5 is-paddingless">
                <figure class="image">
                  <img :src="`${publicPath}imgs/Custom-Fazer-Minun-Sininen-693x430.jpg`" alt="Suklaalevy kuva"/>
                </figure>
              </div>
            </div>
            <div class="columns mt-6">
              <div class="column is-10 is-offset-1 has-blue-border">
                <h3 class="has-text-left">
                  * Karl Fazer –suklaalevy: Mikäli haluat tilata pienempiä määriä tai yksittäisen personoidun levyn, tee
                  tilaus kuluttajaverkkokaupassamme
                  <a
                    href="https://fi.fazer.com/products/suunnittele-ja-laheta-lahjaksi-karl-fazer-suklaalevy"
                    target="_blank"
                    >Fazer Storessa</a
                  >
                </h3>
              </div>
            </div>
            <div class="columns bottom-landing pb-2 mb-0">
              <div class="column is-10 is-offset-1">
                <h2 class="mt-6 mb-4">Palvelun kuvaus lyhyesti</h2>
                <p>
                  Palvelussa voit personoida oman Fazer-tuotepakkauksen. Tuotteesta riippuen voit itse suunnitella
                  tuotteen tekstit, lisätä kuvan sekä logon. Minimitilausmäärä on riippuvainen valitusta tuotteesta.
                  Kuluttajille maksutapana on yleisimmät verkkomaksutavat, kuten pankkimaksu sekä luottokorttimaksut.
                  Yritykset voivat maksaa sekä verkkomaksutavoilla että laskulla. Tuotteiden toimitusaika on noin 3
                  viikkoa. Lue lisää palvelun sopimus- ja toimitusehdoista
                  <router-link :to="{ name: 'rules' }">täältä</router-link>.
                </p>
                <h2 class="mt-6 mb-4">Hinnasto</h2>
                <p>
                  Palvelun hinta riippuu tuotteesta. Toimituskulut riippuvat toimitustavasta ja tilatusta määrästä.
                  Katso tuotekohtaiset hinnat <router-link :to="{ name: 'pricing' }">täältä</router-link>.
                </p>
                <p>Psst. Varainhankintaan hankitut tilaukset -15%</p>
                <h2 class="mt-6 mb-4">Kuinka tehdä täydellinen paketti?</h2>
                <p>
                  Kun otat suunnittelussa tekstien, kuvien ja logojen kanssa huomioon muutaman asian, niin et voi
                  epäonnistua. Katso vastaukset
                  <router-link :to="{ name: 'faq' }">usein kysyttyihin kysymyksiin</router-link> ja
                  <router-link :to="{ name: 'hints' }">vinkkimme onnistuneeseen lopputulokseen</router-link>.
                </p>
              </div>
            </div>
            <div class="column is-10 is-offset-1 mb-2">
              <h2 class="is-center">Vain mielikuvituksesi on rajana</h2>
            </div>
            <div class="bottom-mosaic mb-6">
              <div class="columns">
                <div class="column is-3 is-offset-1">
                  <img :src="`${publicPath}imgs/KF270-1.jpg`" alt="konvehtirasia esimerkki kuva numero 1"/>
                </div>
                <div class="column is-3">
                  <img :src="`${publicPath}imgs/KF270-2.jpg`" alt="konvehtirasia esimerkki kuva numero 2"/>
                </div>
                <div class="column is-3">
                  <img :src="`${publicPath}imgs/KF270-3.jpg`" alt="konvehtirasia esimerkki kuva numero 3"/>
                </div>
              </div>
              <div class="columns">
                <div class="column is-3 is-offset-1">
                  <img :src="`${publicPath}imgs/domino-1.jpg`" alt="domino esimerkki kuva numero 3"/>
                </div>
                <div class="column is-3">
                  <img :src="`${publicPath}imgs/domino-2.jpg`" alt="domino esimerkki kuva numero 3"/>
                </div>
                <div class="column is-3">
                  <img :src="`${publicPath}imgs/domino-3.jpg`" alt="domino esimerkki kuva numero 3"/>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import SectionHeader from '@/components/SectionHeader'
import Footer from '@/components/Footer'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { setAxiosAuthToken } from '@/utils'
import { useMeta } from 'vue-meta'
import DisabledSiteModal from '../components/modals/DisabledSiteModal'
import asModalDialog from '@/components/helpers/asModalDialog'

let initial_errors = {
  non_field_errors: [],
}

export default {
  name: 'Home',
  data() {
    return {
      publicPath: process.env.BASE_URL,
    }
  },
  components: {
    SectionHeader,
    Footer,
    DisabledSiteModal,
    asModalDialog
  },
  mounted() {
    window.scrollTo(0, 0)
    this.getSettings()
    if (this.$store.getters.getReseting) {
      this.setReseting(false)
      this.resetData()
    }

    this.domainCalendarCheck()

    if (!this.getCalendarMode()) {
      useMeta({
        title: 'Luo oma Fazer-tuotepakkauksesi – Custom Fazer',
        description:
          'Personoitu herkkupaketti yllättää vastaanottajan iloisesti. Sopii liike- ja asiakaslahjaksi, henkilöstön muistamiseen tai vaikka urheiluseuran varainhankintaan.',
      })
    } else {
      useMeta({
        title: 'Luo oma suklaajoulukalenterisi – Fazer Oma kalenteri',
        description:
          'Suklaakalenteri lempikuvallasi piristää joulun odotusta päivittäin. Suunnittele oma kalenteri ja tilaa persoonallinen kalenteri itsellesi tai läheisillesi.',
      })
    }

    this.listLandingTexts()
    this.listLandingTitles()
    this.listDiscountVolumes()
    this.listShippingPrices()
    this.listFreightPrices()
    this.listPolicies()
    this.getProducts()
    this.setSpinnerState(false)
    this.setCartSpinnerState(false)
    // check if there is a saved session and it expired
    if (this.getOrderData() != null && this.getOrderData().id != null) {
      this.checkExpirationSession({ product_id: this.getOrderData().id }).then(() => {
        if (this.$store.state.checkExpirationSessionStatusCode != 200) {
          this.resetData()
        }
      })
    }
  },
  methods: {
    ...mapMutations(['setSpinnerState', 'setCartSpinnerState', 'resetData', 'setReseting']),
    ...mapGetters({
      getMaintenanceSetting: 'getMaintenanceSetting',
      getOrderData: 'getOrderData',
      getLandingTexts: 'getLandingTexts',
      getLandingTitles: 'getLandingTitles',
      getCalendarMode: 'getCalendarMode',
    }),
    ...mapActions([
      'login',
      'getProducts',
      'listDiscountVolumes',
      'listShippingPrices',
      'listPolicies',
      'listFreightPrices',
      'listLandingTexts',
      'listLandingTitles',
      'getSettings',
      'checkExpirationSession',
      'postFazerBar',
      'postMarianneBox',
      'postCustomCandyBox',
      'postFazerBox',
      'domainCalendarCheck',
    ]),
    routerParamToProductId(param) {
      if (param == 'fazer-bar') {
        return 'bar'
      } else if (param == 'marianne') {
        return 'marianne'
      } else if (param == 'custom-candy-box') {
        return 'custom-candy-box'
      } else if (param == 'fazer') {
        return 'blue'
      }
    },
    getPostFunction(item) {
      const products = {
        'fazer-bar': this.postFazerBar,
        marianne: this.postMarianneBox,
        'custom-candy-box': this.postCustomCandyBox,
        fazer: this.postFazerBox,
      }
      return products[item]
    },
    goToEditor(item) {
      /*if (item == 'calendar') {
        this.$refs.calendarsDisabled.showModal()
        return
      }*/
      let self = this
      self.setSpinnerState(true)
      this.login({})
        .then(() => {
          let token = self.$store.state.postLoginData.token
          setAxiosAuthToken(token)
        })
        .then(() => {
          if (item == 'domino' || item == 'calendar' || item == 'calendar-b2b') {
            self.setSpinnerState(false)
            self.$router.push({ name: 'upload', params: { product: item } })
          } else {
            let data = {
              product: this.$store.getters.getProductId(this.routerParamToProductId(item)),
              type: 'ALL_TO_SAME_ADDRESS', // DEFAULT, it doesnt really mean anything yet, it will be configured on the next page
              csv_status: 'NO_CSV_NEEDED', // DEFAULT, it doesnt really mean anything yet, it will be configured on the next page
              draft: true,
            }
            if (this.$store.state.order != null) {
              let order_id = this.$store.state.order.id
              if (order_id != undefined) {
                data['order_id'] = order_id
              }
            }
            this.getPostFunction(item)(data).then(() => {
              self.setSpinnerState(false)
              if (item == 'fazer') {
                self.$router.push({ name: 'editor', params: { uuid: self.getOrderData().last_product } })
              } else {
                self.$router.push({ name: 'editor-tab', params: { uuid: self.getOrderData().last_product } })
              }
            })
          }
        })
        .catch(function (error) {
          if (!error.response) {
            // no response from server.. maybe network down?
            self.setSpinnerState(false)
            self.errors = Object.assign({}, initial_errors, { non_field_errors: [error.message] }) // combine initial and response
          } else {
            self.setSpinnerState(false)
            self.errors = Object.assign({}, initial_errors, error.response.data) // combine initial and response
          }
        })
    },
    closeMenu() {
      this.menuActive = false
    },
  },
  computed: {
    getListLandingTitles() {
      if (this.getLandingTitles()[0] != undefined) {
        return this.getLandingTitles()
      } else {
        return [{ title: 'Tervetuloa luomaan oma Fazer-tuotepakkauksesi' }]
      }
    },
    getListLandingTexts() {
      if (this.getLandingTexts()[0] != undefined) {
        return this.getLandingTexts()
      } else {
        return [
          {
            text: 'Tee lähtemätön vaikutus! Personoidut tuotteet ovat täydellinen yrityslahja tapahtumiin, asiakkaille tai henkilöstölle. Sopii hyvin myös urheiluseuran tukemiseen. Palvelu on tarkoitettu sekä yritys- että kuluttaja-asiakkaille. ',
          },
        ]
      }
    },
  },
}
</script>

<style lang="scss">
@import '../assets/sass/mybulma';

.is-calendar {
  background-color: $primary;
  .hero {
    border-bottom: 1px solid #FFFFFF;
  }
  .container {
    background-color: $primary !important;
    h1,
    h2,
    h3,
    p,
    a,
    span {
      color: #fff;
    }
  }
  .front-page-box-column {
    background-color: #134094;
    .front-page-box {
      background-color: #134094;
    }
  }
  .has-blue-border {
    border: 1px solid #FFFFFF;
  }
}

.hero-body {
  padding-top: 11px;
  padding-bottom: 0px;

  .container {
    background-color: white;
    text-align: center;
  }
  @media screen and (max-width: 770px) {
    padding: 0px !important;
  }
}

.box-wrapper-mobile {
  .front-page-box {
    height: 100%;
    position: relative;
    button {
      position: absolute;
      bottom: 5%;
    }
  }
}

@media screen and (max-width: 1023px) {
  .mx-1-is-touch {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.front-page-box-column {
  background-color: #ebeff5;
  padding: 30px;
  height: auto;
  @media screen and (max-width: 1700px) and (min-width: 1216px) {
    h3 {
      font-size: 21px;
    }
    p {
      font-size: 17px;
    }
  }
  @media screen and (max-width: 1216px) and (min-width: 770px) {
    h3 {
      font-size: 14px;
    }
    p {
      font-size: 14px;
    }
  }

  @media screen and (min-width: 1700px) {
    padding: 50px;
    h3 {
      margin-bottom: 25px !important;
    }
    p {
      margin-bottom: 20px !important;
    }
  }

  .front-page-box {
    background-color: #ebeff5;
    text-align: left;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    @media screen and (max-width: 1700px) and (min-width: 1216px) {
      padding-top: 0px;
      padding-left: 10px;
      padding-right: 10px;
      h3 {
        margin-bottom: 5px;
      }
      p {
        margin-bottom: 10px !important;
      }
    }
    @media screen and (max-width: 1215px) and (min-width: 901px) {
      padding-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
      h3 {
        margin-bottom: 5px !important;
      }
      p {
        margin-bottom: 5px !important;
        font-size: 12px !important;
      }
      .upload-button-small-box {
        margin-top: 30px;
      }
      .upload-button {
        height: 30px;
        width: 120px;
      }
    }
    @media screen and (max-width: 900px) and (min-width: 768px) {
      padding-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
      h3 {
        margin-bottom: 5px !important;
      }
      p {
        margin-bottom: 1px !important;
        font-size: 10px !important;
      }
      .upload-button-small-box {
        margin-top: 30px;
      }
      .upload-button {
        height: 20px;
        width: 80px;
        font-size: 13px;
      }
    }
    @media screen and (max-width: 769px) {
      padding: 15px;
    }
    h3 {
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 20px;
    }
  }
}

.bottom-landing {
  text-align: left;
  .column {
    @media screen and (max-width: 770px) {
      margin: 15px;
    }
  }
}

.custom-file-upload:hover {
  cursor: pointer;
}

.upload-button {
  font-family: gill-sans-nova;
  font-size: 20px;
  font-weight: bold;
  border-radius: 20px;
  height: 40px;
  width: 145px;
  span {
    position: relative;
    top: -2px;
    width: 100%;
  }
}

.landingpage-list {
  text-align: left;
  width: 55%;
  margin-left: auto;
  margin-right: auto;
  li {
    list-style: initial;
  }
  p {
    display: list-item;
    list-style-type: disc;
    list-style-position: inside;
  }
}

a {
  color: black;
  text-decoration: underline;
  font-weight: bold;
}

.slick-dots {
  width: 180px;
  padding-left: 20px;
  height: 20px;
}

p.home-top-text {
  font-size: 24px;
}

h1.home-top-text {
  @media screen and (max-width: 769px) {
    padding-top: 20px;
    font-size: 2.5rem;
  }
}

.home-top-text {
  @media screen and (max-width: 769px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.has-blue-border {
  border: 1px solid #15256b;
  h3 {
    font-size: 24px;
    @media screen and (max-width: 769px) {
      padding: 20px;
    }
    a {
      text-decoration: underline;
    }
    a:hover {
      color: #15256b;
    }
  }
}
</style>
