<template>
  <div class="colorPickerExtraWrapper has-text-centered">
    <p class="has-text-centered label-bar-color">Nykyinen valinta:</p>
    <div class="colorPickerBar" :style="{backgroundColor: bgColor || '#44BDC7'}"></div>

    <button class="mt-3 mr-1-tablet color-picker-button button is-primary" @click="confirmButton()" tabindex="0" role="button" v-on:keyup.enter="confirmButton()">
      <span>Valitse väri</span>
    </button>

    <button class="mt-3 ml-1-tablet color-picker-button button is-primary" @click="cancelButton()" tabindex="0" role="button" v-on:keyup.enter="cancelButton()">
      <span>Peruuta</span>
    </button> 
  </div>
</template>

<script>

export default {
  name: "ColorPickerFooter",
  props: ['bgColor',],
  data(){
    return {
      
    }
  },
  methods: {
    confirmButton() {
      this.$emit('cp-confirmButton')
    },
    cancelButton() {
      this.$emit('cp-cancelButton')
    }
  }
}
</script>

<style scoped lang="scss">
@import '../assets/sass/mybulma';

.colorPickerExtraWrapper {
  padding: 6px;
}

.colorPickerBar {
  height: 25px;
  width: 100%;
}

@media screen and (min-width: 769px){
  .mr-1-tablet {
    margin-right: 0.25rem !important;
  }
  .ml-1-tablet {
    margin-left: 0.25rem !important;
  }
}

.label-bar-color {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-size: 12.8px;
  color: navy;
}

.color-picker-button {
  span {
    color: white;
  }
}
</style>
