<template>
  <div class="upload">

  <asModalDialog ref="uploadError" heading-text="Error" :show-ok="false" :show-cancel="false">
    <p>Virhe ladattaessa kuvaa. Tiedostomuodon tulee olla jpg tai png. Tiedoston maksimikoko on 30MB.</p>
  </asModalDialog>

    <section class="hero is-fullheight">
      <SectionHeader mode="home"/>
      <div class="hero-body">
        <div class="container ">
          <div class="columns mb-6">
            <div class="column is-full">
              <h1 class="mb-5">Lataa kuva</h1>
              <p class="mb-5">
                Aloita valitsemalla kuva, jonka haluat pakkaukseen.
              </p>
              <button
                  class="upload-button button is-primary"
                  @click="$refs.file.click()"
                  aria-label="lata domino kuva"
                >
                  <input style="display: none" type="file" ref="file" @change="uploadPicture($event)" accept=".png, .jpg, .jpeg">
                  <span>Lataa kuva</span>
                </button>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </section>
  </div>
</template>

<script>
// initial errors prevent vue template to getting lenght of null
import {setAxiosAuthToken} from '@/utils'
import SectionHeader from '@/components/SectionHeader'
import Footer from '@/components/Footer'
import {mapMutations, mapActions, mapGetters} from 'vuex'
import {useMeta} from "vue-meta"
import asModalDialog from '@/components/helpers/asModalDialog'

let initial_errors = {
  non_field_errors: [],
}

export default {
  name: 'Upload',
  components: {
    SectionHeader,
    Footer,
    asModalDialog
  },
  mounted() {
    if (this.$store.state.postLoginData == null || this.$store.state.postLoginData.token == null) {
      this.$router.push('/')
    }
    if (this.getCalendarMode()) {
      useMeta({
        title: 'Fazer Oma kalenteri',
        description: 'Fazer Oma kalenteri palvelussa voit tilata itse suunnittelemasi suklaajoulukalenteri.'
      })
    }
  },
  methods: {
    ...mapMutations([
      'setSpinnerState',
      'setCartSpinnerState'
    ]),
    ...mapGetters({
      getProductId: 'getProductId',
      getCalendarMode: 'getCalendarMode'
    }),
    ...mapActions([
      'postImage',
      'postCalendar',
      'login'
    ]),
    getUploadAction(product) {
      const actions = {
        'domino': this.postImage,
        'calendar': this.postCalendar,
        'calendar-b2b': this.postCalendar
      }
      return actions[product]
    },
    getLastProduct(product) {
      if (product == 'domino') {
        return this.$store.state.postImagesData.last_product
      } else if (product == 'calendar' || product == 'calendar-b2b') {
        return this.$store.state.postCalendarData.last_product
      }
    },
    uploadPicture(event) {
      let order_id = null
      if (this.$store.state.order != null) {
        order_id = this.$store.state.order.id
      }
      let product = this.$route.params.product
      this.setSpinnerState(true)
      this.setCartSpinnerState(true)
      let self = this
      let files = event.target.files || event.dataTransfer.files
      if (!files.length) return
      // login first and create a django user and get its token
      this.login({})
          .then(() => {
            let token = self.$store.state.postLoginData.token
            setAxiosAuthToken(token)
          })
          .then(() => {
            // upload picture and create the product
            let reader = new FileReader()
            reader.readAsDataURL(files[0])
            reader.onload = e => {
              let imageData = {
                'image': e.target.result,
                'product': this.$store.getters.getProductId(product),
                'draft': true
              }
              if (order_id != null) {
                imageData['order_id'] = order_id
              }
              if (product == 'calendar-b2b') {
                imageData['b2b'] = true
              }
              this.getUploadAction(product)(imageData).then((data) => {
                self.setSpinnerState(false)
                self.setCartSpinnerState(false)
                if (self.$store.state.POST_CALENDAR_FAILURE != null) {
                  self.$refs.uploadError.showModal()
                } else {
                  self.$router.push({name: 'editor', params: {uuid: this.getLastProduct(product)}})
                }
              })
            }
          })
          .catch(function (error) {
            if (!error.response) {
              // no response from server.. maybe network down?
              self.setSpinnerState(false)
              self.setCartSpinnerState(false)
              self.errors = Object.assign({}, initial_errors, {non_field_errors: [error.message]}) // combine initial and response
            } else {
              self.setSpinnerState(false)
              self.setCartSpinnerState(false)
              self.errors = Object.assign({}, initial_errors, error.response.data) // combine initial and response
            }
          })
    },
    closeMenu(){
      this.menuActive = false;
    }
  },
}
</script>

<style lang="scss" scoped>
.hero-body {
  background-color: white;
}
</style>
