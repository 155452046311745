<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content}` : `Custom Fazer` }}</template>
  </metainfo>
  <div id="nav"></div>
  <SpinnerModal :spinning="spinnerActive"></SpinnerModal>
  <notificationModal></notificationModal>
  <router-view/>
</template>

<script>
import {setAxiosAuthToken, waitForStorageToBeReady} from "@/utils"
import {mapState} from "vuex"
import SpinnerModal from '@/components/modals/SpinnerModal'
import notificationModal from "@/components/modals/notificationModal"
import { useMeta } from 'vue-meta'

export default {
  name: 'DominoApp',
  setup () {
    useMeta({
      title: '',
      htmlAttrs: { lang: 'fi', amp: true }
    })
  },
  async mounted() {
    await waitForStorageToBeReady()
    if (this.$store.state.postLoginData != null) {
      let token = this.$store.state.authenticated
      setAxiosAuthToken(token)
    }
  },
  components: {
    SpinnerModal,
    notificationModal
  },
  computed: {
    ...mapState([
      'spinnerActive',
    ])
  }
}

</script>
<style lang="scss">
.cr-image {
  z-index: 1 !important;
}
</style>
