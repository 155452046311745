<template>
  <div class="hero is-fullheight">
    <SectionHeader></SectionHeader>
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <div class="columns mt-2">
            <div class="column mt-6 is-12 is-mobile has-text-left">
              <h1 v-if="status == 'PAID'" class="has-text-weight-bold">Kiitos tilauksestasi! Olemme vastaanottaneet maksun.</h1>
              <h1 v-else-if="status == 'ORDERED'" class="has-text-weight-bold">Kiitos tilauksestasi!</h1>
              <h1 v-else-if="status == 'PAYTRAIL_CANCEL'" class="has-text-weight-bold">Maksutapahtumassa on ilmennyt virhe.</h1>
              <h1 v-else>Maksu peruutettu</h1>
              <br />
              <p v-if="status == 'PAID' || status == 'ORDERED'" >Tilauksesi on vastaanotettu ja se odottaa Fazerin vahvistusta.</p>
              <br /><br />
              <p v-if="status == 'PAID' || status == 'ORDERED'">Tilauksen yhteenveto on toimitettu osoitteeseen {{ email }}.</p>
              <p v-if="status == 'PAID' || status == 'ORDERED'">Tarkistathan myös roskaposti-kansiosi, jos tilausvahvistus ei näy saapuneissa sähköposteissa.</p>
              <div class="mt-6">
                <button @click="goToHome" class="button is-primary">Takaisin etusivulle</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import SectionHeader from '@/components/SectionHeader'
import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'
import {useMeta} from "vue-meta"
import Footer from '@/components/Footer'

export default {
  name: 'ThankYou',
  data() {
    return {
      uploading: false,
      email: null,
      status: null,
    }
  },
  components: {
    SectionHeader,
    Footer
  },
  computed: {
    ...mapState(['order', 'putContactData', 'postContactData']),
  },
  methods: {
    ...mapMutations({
      resetData: 'resetData',
      setSpinnerState: 'setSpinnerState',
      setCartSpinnerState: 'setCartSpinnerState',
      setDomainCalendar: 'setDomainCalendar'
    }),
    ...mapActions({
      getOrder: 'getOrder'
    }),
    ...mapGetters({
      getCalendarMode: 'getCalendarMode'
    }),
    goToHome() {
      this.$router.push('/')
    },
  },
  mounted() {
    if (this.$store.state.postLoginData == null || this.$store.state.postLoginData.token == null) {
      this.$router.push('/')
    }
    if (this.getCalendarMode()) {
      useMeta({
        title: 'Fazer Oma kalenteri',
        description: 'Fazer Oma kalenteri palvelussa voit tilata itse suunnittelemasi suklaajoulukalenteri.'
      })
    } else {
      useMeta({
        title: 'Kiitos tilauksestasi – Custom Fazer',
        description: 'Kiitos tilauksestasi. Custom Fazer -palvelu tarjoaa personoituja tuotteita liike- ja asiakaslahjaksi ja henkilöstön muistamiseen.'
      })
    }
    this.setSpinnerState(false)
    this.setCartSpinnerState(false)
  },
  beforeMount() {
    this.getOrder().then(() => {
      let order = this.$store.getters.getOrderData
      if (['ORDERED', 'PAID', 'PAYTRAIL_CANCEL'].indexOf(order['status']) === -1) {
        this.$router.push('/')
      } else {
        this.status = order.status
        this.email = this.postContactData.cust_email
        if (this.putContactData) this.email = this.putContactData.cust_email
        if (order['status'] != 'PAYTRAIL_CANCEL') {
          this.resetData()
        } else {
          useMeta({
            title: 'Maksutapahtuman virhe – Custom Fazer',
            description: 'Maksutapahtuman virhe. Custom Fazer -palvelu tarjoaa personoituja tuotteita liike- ja asiakaslahjaksi ja henkilöstön muistamiseen.'
          })
        }
      }
      this.setSpinnerState(false)
    })
  },
}
</script>

<style lang="scss"></style>
