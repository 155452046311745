<template>
  <div class="columns is-multiline is-relative">
    <div class="column is-3 is-8-mobile is-offset-2-mobile bottom-aligned pl-0" v-if="this.productType.product.name != 'calendar'">
      <div class="discount-box-wrapper">
        <div id="bulk-discount-box" v-bind:class="{'expanded': expandedBox}">
          <p class="bulk-discount-title mt-2 has-text-left ml-2">Määräalennukset</p>
          <p class="bulk-discount-row" v-for="(volume, idx) in getDiscounts()"
             v-bind:class="{ 'grey-background': idx % 2 == 0 }">
            <span class="is-pulled-left ml-2 pt-2 pb-2">{{volume.volume_point}} kpl</span>
            <span class="is-pulled-right mr-2 pt-2 pb-2">à {{volume.price}}€</span>
          </p>
        </div>
        <p v-if="!expandedBox" class="expandText mt-4 mb-2" @click="expandedBox=!expandedBox" tabindex="0" role="button" v-on:keyup.enter="expandedBox=!expandedBox">Näytä enemmän<i class="fas fa-chevron-down ml-3"></i></p>
        <p v-else class="expandText mt-4 mb-2" @click="expandedBox=!expandedBox" tabindex="0" role="button" v-on:keyup.enter="expandedBox=!expandedBox">Näytä vähemmän<i class="fas fa-chevron-up ml-3"></i></p>
      </div>
    </div>
    <div class="column is-12-mobile mb-3 is-offset-4 is-5 footer-editor-actions-wrapper">
      <button
        class="upload-button button is-primary"
        @click="goToShoppingCart()"
      >
        <span>Tallenna ja siirry ostoskoriin</span>
      </button>
      <button
        class="upload-button button is-primary mt-4"
        @click="goToEditorPage()"
      >
        <span>Tallenna ja lisää uusi tuote</span>
      </button>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  name: 'editorFooter',
  props: [
    'productId',
    'productType'
  ],
  emits: ['safe-link'],
  data () {
    return {
      expandedBox: false
    }
  },
  methods: {
    ...mapMutations([
      'setSpinnerState',
      'setNotificationActiveState'
    ]),
    copyToClipboard() {
      navigator.clipboard
        .writeText(this.linkUri)
        .then(() => {
          this.$refs.linkModal.answerNAY()
        })
        .catch(() => {
          console.log('cannot paste to clipboard')
        })
    },
    goToShoppingCart() {
      this.$emit("continue-to-cart")
    },
    goToEditorPage() {
      this.setNotificationActiveState({'notificationState': true, 'message': "Uusi tuote lisättiin ostoskoriin. Lisää oma tekstisi ja/tai kuvasi pakkaukseen."})
      if (this.productType.product.name == "domino") {
        this.$emit("continue-to-domino-editor")
      } else if (this.productType.product.name == "blue"){
        this.$emit("continue-to-fazer-editor")
      } else if ((this.productType.product.name == "bar")) {
        this.$emit("continue-to-bar-editor")
      } else if ((this.productType.product.name == "marianne")) {
        this.$emit("continue-to-marianne-editor")
      } else if (this.productType.product.name == "custom-candy-box") {
        this.$emit("continue-to-candy-box-editor")
      } else if (this.productType.product.name == "calendar") {
        this.$emit("continue-to-calendar-editor")
      } else if (this.productType.product.name == "calendar-b2b") {
        this.$emit("continue-to-calendar-editor")
      }
    },
    getDiscounts() {
      return this.$store.getters.getVolumesDiscount(
        this.productType.product.name
      )
    }
  }
}
</script>

<style lang="scss" scoped>

@import '../assets/sass/mybulma';

button {
  min-width: 280px;
  @media screen and (max-width: 500px) {
    font-size: 10px;
    //width: 100%;
  }
}

.footer-editor-actions-wrapper {
  text-align: right;
  @media screen and (min-width: 769px) {
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
  }
  @media screen and (max-width: 770px) {
    text-align: center;
  }
}

.help {
  @media screen and (max-width: 500px) {
    font-size: 0.8rem;
  }
}

p {
  color: $primary;
  font-family: gill-sans-nova;
}

span {
  font-family: gill-sans-nova;
}

svg {
  color: $primary;
}

.button {
  font-family: gill-sans-nova;
  font-size: 20px;
  font-weight: bold;
  border-radius: 20px;
  height: 40px;
  width: 180px;
  span {
    position: relative;
    top: -2px;
    width: 100%;
  }
}

.discount-box-wrapper {
  border: 2px solid $primary;
}

#bulk-discount-box.expanded {
  max-height: none;
  overflow: auto;
}

#bulk-discount-box {
  max-height: 150px;
  overflow: hidden;
  background-color: white;
  .bulk-discount-title {
    font-family: gill-sans-nova;
    font-size: 22px;
    font-weight: bold;
  }
  .grey-background {
    background-color: #EBEFF5;
  }
  p {
    display: flow-root;
  }
  .bulk-discount-row {
    font-size: 0.9em;
  }
}

.expandText {
  font-size: 15px;
  font-weight: bold;
}
.expandText:hover {
  cursor: pointer;
  text-decoration: underline;
}


</style>
