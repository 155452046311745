<template>
  <div class="menu hero-head" :class="{ 'is-calendar': getCalendarMode()}">
    <nav class="navbar is-hidden-desktop" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <div class="home-image mt-0">
          <a class="navbar-item p-0" href="/">
            <img style="max-height: 145px" src="../assets/img/fazer_logo.png" alt="Fazer logo ja kinkki palvelun etusivulle" v-if="!getCalendarMode()"/>
            <img style="max-height: 145px" :src="`${publicPath}imgs/calendar-logo.svg`" alt="Calendar logo ja kinkki palvelun etusivulle" v-else/>
          </a>
        </div>
        <div v-if="!getMaintenanceSetting().enabled" class="popover is-popover-bottom" @click="clickAddProductModal()" aria-label="Lisää tuote" tabindex="0" role="button">
          <OnClickOutside @trigger="addProductModal=false">
            <template v-if="getCalendarMode()">
              <!--a class="add-product-button mt-0 popover-trigger is-hidden-mobile">
                <i class="fa-solid fa-plus" style="color: #FFFFFF;"></i>
                Lisää Tuote
              </a>
              <a class="add-product-button mt-0 popover-trigger is-hidden-tablet">
                <i class="fa-solid fa-plus" style="color: #FFFFFF;"></i>
              </a-->
            </template>
            <template v-else>
              <a class="add-product-button mt-0 popover-trigger">
                <i class="fa fa-plus is-hidden-tablet" aria-hidden="true"></i>
                <img src="../assets/img/lisaatuote.png" class="is-hidden-mobile" alt="Lisää tuote"/>
              </a>
            </template>

            <add-product-modal v-if=addProductModal></add-product-modal>
          </OnClickOutside>
        </div>
        <div v-if="!getMaintenanceSetting().enabled" class="popover is-popover-bottom" @click="clickCartModal()" aria-label="Ostoskori" tabindex="0" role="button">
          <OnClickOutside @trigger="openCartModal=false">
            <a class="shopping-cart-button mt-0 popover-trigger is-relative">
              <span v-if="$store.getters.getCartSpinnerState" >
                <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
              </span>
              <span v-else>
                <span class="product-length-bubble" v-if="getProductsLength>0" aria-label="Ostosten määrä yhteensä">{{getProductsLength}}</span>
                <i v-if="!$store.getters.getCartSpinnerState" class="fa fa-shopping-cart" aria-hidden="true"></i>
              </span>
            </a>
            <cart-modal v-if=openCartModal></cart-modal>
          </OnClickOutside>
        </div>

        <OnClickOutside @trigger="menuActive=false">
          <a role="button" class="navbar-bigburger burger"
             :class="{'is-active': menuActive}"
             @click="openInfoModal()"
             aria-label="menu" aria-expanded="false"
             data-target="navbarBasicExample">
            <span v-bind:class="{ active: menuActive }" aria-hidden="true"></span>
            <span v-bind:class="{ active: menuActive }" aria-hidden="true"></span>
            <span v-bind:class="{ active: menuActive }" aria-hidden="true"></span>
          </a>
        </OnClickOutside>
      </div>

      <div id="navbarMobile" class="navbar-item has-dropdown" v-show="menuActive">
        <div class="navbar-start">
          <router-link :to="{ name: 'faq',}" class="navbar-item">
            Usein kysytyt kysymykset
          </router-link>
          <router-link :to="{ name: 'pricing',}" class="navbar-item" v-if="!getCalendarMode()">
            Hinnasto
          </router-link>
          <router-link :to="{ name: 'rules',}" class="navbar-item">
            Tilaus ja toimitusehdot
          </router-link>
          <router-link :to="{ name: 'hints',}" class="navbar-item" v-if="!getCalendarMode()">
            Vinkkejä pakkauksen suunnitteluun
          </router-link>
          <router-link :to="{ name: 'fundraising',}" class="navbar-item" v-if="!getCalendarMode()">
            Varainhankinta
          </router-link>
          <router-link :to="{ name: 'form',}" class="navbar-item">
            Ota yhteyttä
          </router-link>
        </div>
      </div>
    </nav>


    <nav class="navbar is-hidden-touch">
      <div class="navbar-brand">
        <div class="home-image">
           <a class="navbar-item" href="/">
             <img style="max-height: 145px" src="../assets/img/fazer_logo.png" alt="Fazer logo ja kinkki palvelun etusivulle" v-if="!getCalendarMode()"/>
             <img style="max-height: 145px" :src="`${publicPath}imgs/calendar-logo.svg`" alt="Calendar logo ja kinkki palvelun etusivulle" v-else/>
           </a>
         </div>
      </div>

      <div id="navbarDesktop" class="navbar-menu">
        <div class="navbar-start">
          <div class="navbar-item">
              <router-link :to="{ name: 'faq',}" class="navbar-item"  role="menuitem">
                Usein kysyttyä
              </router-link>
          </div>
          <div class="navbar-item" v-if="!getCalendarMode()">
            <router-link :to="{ name: 'pricing',}" class="navbar-item"  role="menuitem">
              Hinnasto
            </router-link>
          </div>
          <div class="navbar-item">
            <router-link :to="{ name: 'rules',}" class="navbar-item"  role="menuitem">
              Tilausehdot
            </router-link>
          </div>
          <div class="navbar-item">
            <router-link :to="{ name: 'hints',}" class="navbar-item" v-if="!getCalendarMode()"  role="menuitem">
              Suunnitteluvinkit
            </router-link>
          </div>
          <div class="navbar-item" v-if="!getCalendarMode()">
            <router-link :to="{ name: 'fundraising',}" class="navbar-item"  role="menuitem">
              Varainhankinta
            </router-link>
          </div>
          <div class="navbar-item">
            <router-link :to="{ name: 'form',}" class="navbar-item"  role="menuitem">
              Ota yhteyttä
            </router-link>
          </div>
        </div>

        <div class="navbar-end">
          <div class="navbar-item navbar-item-border" v-if="!getCalendarMode()">
            <div class="field is-grouped">
              <p class="control">
                <div v-if="!getMaintenanceSetting().enabled" class="popover is-popover-bottom" @click="clickAddProductModal()">
                  <OnClickOutside @trigger="addProductModal=false" class="click-outside">
                    <template v-if="getCalendarMode()">
                      <!--a class="add-product-button mt-0 popover-trigger">
                        <i class="fa-solid fa-plus" style="color: #FFFFFF;"></i>
                        Lisää Tuote
                      </a-->
                    </template>
                    <template v-else>
                      <a class="add-product-button mt-0 popover-trigger" tabindex="0" role="button"
                         v-on:keyup.enter="clickAddProductModal()"
                         v-on:keyup.esc="addProductModal=false"
                         aria-label="Lisää tuote"
                         aria-haspopup="true" :aria-expanded="addProductModal ? 'true' : 'false'"
                         ref="addProductModalDesktop"
                      >
                        <i class="fa fa-plus is-hidden-tablet" aria-hidden="true"></i>
                        <img src="../assets/img/lisaatuote.png" class="is-hidden-mobile" alt="Lisää tuote"/>
                      </a>
                    </template>

                    <add-product-modal v-show=addProductModal ref="addProductModalRef"></add-product-modal>
                  </OnClickOutside>
                </div>
              </p>
            </div>
          </div>
          <div class="navbar-item p-0">
            <div class="field is-grouped" style="height: 70%;">
              <p class="control">
                <div v-if="!getMaintenanceSetting().enabled" class="popover is-popover-bottom" @click="clickCartModal()">
                  <OnClickOutside @trigger="openCartModal=false">
                    <a class="shopping-cart-button mt-0 popover-trigger is-relative"
                       tabindex="0" role="button" v-on:keyup.enter="clickCartModal()"
                       v-on:keyup.esc="openCartModal=false"
                       aria-haspopup="true" :aria-expanded="openCartModal ? 'true' : 'false'"
                       :aria-label="'Ostosten määrä yhteensä ' + getProductsLength"
                       ref="openCartModalDesktop"
                    >
                      <span v-if="$store.getters.getCartSpinnerState" >
                        <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                      </span>
                      <span v-else>
                        <span class="product-length-bubble" v-if="getProductsLength>0" aria-hidden="true">{{getProductsLength}}</span>
                        <i v-if="!$store.getters.getCartSpinnerState" class="fa fa-shopping-cart" aria-hidden="true"></i>
                      </span>
                    </a>
                    <cart-modal v-if=openCartModal></cart-modal>
                  </OnClickOutside>
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>

import cartModal from "@/components/modals/cartModal"
import addProductModal from "@/components/modals/addProductModal"
import { OnClickOutside } from '@vueuse/components'
import {mapGetters} from "vuex";

export default {
  name: 'SectionHeader',
  components: {
    cartModal,
    addProductModal,
    OnClickOutside
  },
  methods: {
    ...mapGetters({
      getMaintenanceSetting: 'getMaintenanceSetting',
      getSavedItems: 'getSavedItems',
      getCalendarMode: 'getCalendarMode'
    }),
    closeMenu() {
      this.menuActive = false
    },
    openInfoModal() {
      this.menuActive = !this.menuActive
    },
    clickCartModal() {
      if (this.getSavedItems().length >= 1) {
        this.openCartModal = !this.openCartModal
      }
    },
    clickAddProductModal() {
      this.addProductModal = !this.addProductModal
      this.$nextTick(() => {
        const menuItem = this.$refs.addProductModalRef.$refs.menuList.querySelector("[role='menuitem']");
        if (menuItem) {
          menuItem.focus()
        }
      });
    },
  },
  computed: {
    getProductsLength() {
      return this.$store.getters.getProductsLength
    },
  },
  data() {
    return {
      menuActive: false,
      openCartModal: false,
      addProductModal: false,
      publicPath: process.env.BASE_URL
    }
  },
  watch: {
    '$route.params.uuid': {
      handler: function(search) {
        this.addProductModal = false
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">

@import '../assets/sass/mybulma';

.menu.is-calendar {
  border-top: 10px solid #C69D67;
  background-color : #15256B;
  @media only screen and (min-width: 768px) {
    border-top: 20px solid #C69D67;
  }
  .navbar {
    background-color : #15256B;
    .click-outside {
      margin: auto;
      .add-product-button {
        color: #FFFFFF !important;
        font-size: 16px;
        display: contents;        
      }
    }
  }
  #navbarDesktop {
    .navbar-item-border {
      border-right: 1px solid white;
      border-left: 1px solid white;
    }
    .navbar-start {
      .navbar-item {
        background-color: #15256B !important;
        a {
          color: white;
        }
      }
    }
  }
  .shopping-cart-button {
    color: white;
  }
  @media only screen and (min-width: 768px) {
    border-bottom: none;
  }  
}

.menu {
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  width: 100%;
  border-top: 10px solid $primary;
  @media only screen and (min-width: 768px) {
    border-top: 20px solid $primary;
    border-bottom: 2px solid #503120;
  }

  .navbar-bigburger {
    color: $primary;
    padding-right: 200px;
    @media (max-width: 768px) {
      width: 100px;
      padding-right: 20px;
      span {
        height: 3px;
        width: 19px;
      }
      span.active:nth-child(3) {
        top: calc(50% - -17px);
      }
      span:nth-child(3) {
        top: calc(50% - -14px);
      }
      span:nth-child(2) {
        top: calc(50% - -8px);
      }
      span:nth-child(1) {
        top: calc(50% - -2px);
      }
      span.active:nth-child(1) {
        top: calc(50% - 3px);
      }
    }
  }
  .navbar-bigburger:hover {
    background-color: white;
  }
  .navbar {
    max-width: 1640px;
    width: 100%;
    position: relative;

    @media (max-width: 768px) {
      border-bottom: 2px solid #503120;
    }

    .navbar-start {
      padding: 10px 20px;
      .navbar-item {
        text-align: right;
        font-size: 1.2rem;
        border-bottom: 2px solid #DEDBD9;
        font-weight: 100;
      }
    }

    .home-image {
      width: 100%;
      margin: 0 auto;
      a {
        max-width: 165px;
        img {
          height: 77px;
          width: 77px;
        }
      }
    }

    .navbar-burger {
      //height: 80px;
      background: white;
    }

    #navbarBasic {
      position: absolute;
      top: 77px;
      background: white;
      border: 1px solid black;
      width: 100%;
      @media (min-width: 768px) {
        width: 300px;
        right: 4em;
      }
    }

  }
}

@media screen and (max-width: 768px) {
  .home-image {
    a {
      float: left;
      margin-left: 20px !important;
      img {
        height: 50px;
      }
    }
  }
}

.shopping-cart-button {
  color: $primary;
  font-size: 20px;
  @media only screen and (min-width: 768px) {
    display: flex;
    font-size: 30px;
    margin-left: 40px;
    padding-left: 50px;
  }

  svg {
    margin-top: 19px;
    @media (max-width: 768px) {
      margin-top: 30px;
    }
  }
}

.add-product-button {
  color: $primary;
  font-size: 20px;
  width: 50px;
  display: inline-block;
  line-height: 77px;
  @media only screen and (min-width: 768px) {
    display: inline;
    position: relative;
    font-size: 30px;
    margin-right: 100px;
    padding-right: 110px;
    padding-left: 45px;
    svg {
      margin-top: 19px !important;
    }
  }
  svg {
    margin-top: 30px;
    margin-right: 10px;
  }

  svg:hover {
    color: $primary !important;
  }
}

.add-product-button:hover {
  color: $primary !important;
}

.shopping-cart-button:hover {
  color: $primary;
}

@media only screen and (min-width: 768px) {
  .is-popover-bottom {
    width: 211px;
    display: flex;
  }
}

.product-length-bubble {
  color: $fazer-red;
  text-align: center;
  border-radius: 50%;
  font-size: 15px;
  font-weight: 700;
  box-sizing: border-box;
  position: absolute;
  @media only screen and (min-width: 768px) {
    width: 67px;
    height: 18px;
    vertical-align: middle;
    line-height: 18px;
    position: absolute;
    left: 35px;
  }
  @media screen and (max-width: 768px) {
    width: 26px;
    top: -15px;
  }
}

.popover-background {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  //z-index: 1000;
  //pointer-events: none;
}

#navbarDesktop {
  .navbar-start {
    border-bottom: none;
    .navbar-item {
      font-size: 15px;
      color: $primary;
      border-bottom: none;
      font-weight: 600;
      background-color: white !important;
    }
    div.navbar-item:hover {
      border-bottom: 3px solid $primary;
    }
  }
  .navbar-item-border {
    padding: 0 !important;
    border-right: 1px solid $primary;
    border-left: 1px solid $primary;
  }
}

.is-calendar {
  .is-hidden-desktop {
    .burger {
      color: white !important;
    }

    .navbar-bigburger:hover {
      background-color: transparent !important;
    }

    .navbar-item {
      color: white !important;
    }

    .navbar-item:hover {
      color: $primary !important;
    }
  }
}


#app > div.home.is-calendar > section > div.menu.hero-head.is-calendar > nav.navbar.is-hidden-desktop > div.navbar-brand > div:nth-child(2) > div > a.add-product-button.mt-0.popover-trigger.is-hidden-mobile {
  color: white;
  font-size: 14px;
  display: contents;
}
</style>
