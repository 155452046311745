<template>
  <div class="rules">
    <div class="hero is-fullheight">
    <SectionHeader></SectionHeader>
    <div class="section">
      <div class="container is-relative fix-position">
        <div class="columns">
          <div class="column is-full mb-4">
            <div class="content is-medium">
              <h1 class="has-text-centered">Hinnasto</h1>
              <p>
                Palvelun hinta on riippuvainen valitsemastasi tuotteesta ja tilausmäärästä. Kun ostat enemmän, saat
                tuotteen edullisemmin. Ajantasaisen hinnaston kullekin tuotteelle näet tältä sivulta. Hinnat sisältävät
                alv. 14 %. Hintaan kuuluu valitsemasi tuote itse suunnitellussa pakkauksessa. Toimituskulut lisätään
                tilaukselle.
              </p>
              <p>
                Psst. Palvelumme on mukana tukemassa seurojen ja yhdistysten toimintaa. Kun tilaat tuotteita varainkeruuta
                varten, käytä tilatessa koodia: VARAINHANKINTA ja saat 15 % alennuksen tilauksesta.
              </p>
            </div>
          </div>
        </div>
        <div class="columns content">
          <div class="column is-6">
            <h2 class="mb-5">Custom Fazer Domino</h2>
            <div id="domino-bulk-discount-box" class="bulk-discount-box" role="grid" >
              <p class="bulk-discount-title mt-2 has-text-left ml-2">Domino</p>
              <p class="bulk-discount-row" v-for="(volume, idx) in getDominoDiscounts()"
                 v-bind:class="{ 'grey-background': idx % 2 == 0 }"
                 tabindex="0" role="gridcell" :aria-label="'Domino ' + volume.volume_point + ' pakkauksen tilauserässä hinta ' + volume.price + '€ pakkaus'"
              >
                <span class="is-pulled-left ml-2 pt-2 pb-2">{{volume.volume_point}} kpl</span>
                <span class="is-pulled-right mr-2 pt-2 pb-2">à {{volume.price}}€</span>
              </p>
            </div>
            <button
              class="upload-button button is-primary mt-4"
              @click="goToEditor('domino')"
            >
              <span>Tilaa Dominoita</span>
            </button>
          </div>
          <div class="column is-6">
            <h2 class="mb-5">Custom Fazer Maitosuklaakonvehti 250g</h2>
            <div id="fazer-bulk-discount-box" class="bulk-discount-box" role="grid">
              <p class="bulk-discount-title mt-2 has-text-left ml-2">Maitosuklaakonvehti</p>
              <p class="bulk-discount-row" v-for="(volume, idx) in getFazerDiscounts()"
                 v-bind:class="{ 'grey-background': idx % 2 == 0 }"
                 tabindex="0" role="gridcell" :aria-label="'Konvehtirasia ' + volume.volume_point + ' pakkauksen tilauserässä hinta ' + volume.price + '€ pakkaus'"
              >
                <span class="is-pulled-left ml-2 pt-2 pb-2">{{volume.volume_point}} kpl</span>
                <span class="is-pulled-right mr-2 pt-2 pb-2">à {{volume.price}}€</span>
              </p>
            </div>
            <button
              class="upload-button button is-primary mt-4"
              @click="goToEditor('fazer')"
            >
              <span>Tilaa konvehteja</span>
            </button>
          </div>
        </div>
        <div class="columns content">
          <div class="column is-6">
            <h2 class="mb-5">Custom Candy Box</h2>
            <div id="candybox-bulk-discount-box" class="bulk-discount-box" role="grid">
              <p class="bulk-discount-title mt-2 has-text-left ml-2">Candy Box</p>
              <p class="bulk-discount-row" v-for="(volume, idx) in getCandyBoxDiscounts()"
                 v-bind:class="{ 'grey-background': idx % 2 == 0 }"
                 tabindex="0" role="gridcell" :aria-label="'Karkkipaketti ' + volume.volume_point + ' pakkauksen tilauserässä hinta ' + volume.price + '€ pakkaus'"
              >
                <span class="is-pulled-left ml-2 pt-2 pb-2">{{volume.volume_point}} kpl</span>
                <span class="is-pulled-right mr-2 pt-2 pb-2">à {{volume.price}}€</span>
              </p>
            </div>
            <button
              class="upload-button button is-primary mt-4"
              @click="goToEditor('custom-candy-box')"
            >
              <span>Tilaa Candy Box</span>
            </button>
          </div>          
          <div class="column is-6">
            <h2 class="mb-5">Minun Marianne –paketti</h2>
            <div id="marianne-bulk-discount-box" class="bulk-discount-box" role="grid">
              <p class="bulk-discount-title mt-2 has-text-left ml-2">Marianne</p>
              <p class="bulk-discount-row" v-for="(volume, idx) in getMarianneDiscounts()"
                 v-bind:class="{ 'grey-background': idx % 2 == 0 }"
                 tabindex="0" role="gridcell" :aria-label="'Marianne ' + volume.volume_point + ' pakkauksen tilauserässä hinta ' + volume.price + '€ pakkaus'"
              >
                <span class="is-pulled-left ml-2 pt-2 pb-2">{{volume.volume_point}} kpl</span>
                <span class="is-pulled-right mr-2 pt-2 pb-2">à {{volume.price}}€</span>
              </p>
            </div>
            <button
              class="upload-button button is-primary mt-4"
              @click="goToEditor('marianne')"
            >
              <span>Tilaa Marianne</span>
            </button>
          </div>
        </div>
        <div class="columns content">
          <div class="column is-6">
            <h2 class="mb-5">Minun Sininen -suklaalevy</h2>
            <div id="fazer-bar-bulk-discount-box" class="bulk-discount-box" role="grid">
              <p class="bulk-discount-title mt-2 has-text-left ml-2">Minun Sininen</p>
              <p class="bulk-discount-row" v-for="(volume, idx) in getFazerBarDiscounts()"
                 v-bind:class="{ 'grey-background': idx % 2 == 0 }"
                 tabindex="0" role="gridcell" :aria-label="'Suklaalevy ' + volume.volume_point + ' pakkauksen tilauserässä hinta ' + volume.price + '€ pakkaus'"
              >
                <span class="is-pulled-left ml-2 pt-2 pb-2">{{volume.volume_point}} kpl</span>
                <span class="is-pulled-right mr-2 pt-2 pb-2">à {{volume.price}}€</span>
              </p>
            </div>
            <button
              class="upload-button button is-primary mt-4"
              @click="goToEditor('bar')"
            >
              <span>Tilaa sininen suklaalevy</span>
            </button>
          </div>
          <!--div class="column is-6">
            <h2 class="mb-5">Fazer Oma Kalenteri</h2>
            <div id="calendar-bulk-discount-box" class="bulk-discount-box" role="grid">
              <p class="bulk-discount-title mt-2 has-text-left ml-2">Fazer Oma Kalenteri</p>
              <p class="bulk-discount-row" v-for="(volume, idx) in getCalendarDiscounts()"
                 v-bind:class="{ 'grey-background': idx % 2 == 0 }"
                 tabindex="0" role="gridcell" :aria-label="volume.volume_point + ' pakkauksen tilauserässä hinta ' + volume.price + '€ pakkaus'"
                 >
                <span class="is-pulled-left ml-2 pt-2 pb-2">{{volume.volume_point}} kpl</span>
                <span class="is-pulled-right mr-2 pt-2 pb-2">à {{volume.price}}€</span>
              </p>
            </div>
            <button
              class="upload-button button is-primary mt-4"
              @click="goToEditor('calendar-b2b')"
            >
              <span>Tilaa kalenteri</span>
            </button>
          </div-->
        </div>
        <div class="columns">
          <div class="column is-full mb-4">
            <div class="content is-medium mt-6">
              <h2>Toimituskulut</h2>
              <h3>Postilaatikkotoimitus</h3>
              <p>
                Osa tuotteista on mahdollista toimittaa postilaatikkoon. Postilaatikkotoimituksen hinta on 5,10 € / kpl.
              </p>
              <h3>Rahtitoimitus</h3>
              <p>
                Tuotteet on mahdollista toimittaa isommassa erässä yhteen toimitusosoitteeseen. Useamman toimitusosoitteen
                toimitukset käsitellään tapauskohtaisesti. Toimituskulut lisätään tilaukselle tilauksen painon mukaan.
                Näet lopulliset toimituskulut kassalla. Alla arvioita toimituskuluista painon mukaan.
              </p>
              <p>
                Toimituksen paino 10 kg: 17,78 €<br>
                Toimituksen paino 20 kg: 19,38 €<br>
                Toimituksen paino 50 kg: 24,51 €<br>
                Toimituksen paino 100 kg: 31,92 €<br>
                Toimituksen paino 200 kg: 45,60 €<br>
                Toimituksen paino 500 kg: 84,36 €<br>
                Toimituksen paino 1000 kg: 153,90 €<br>
              </p>
            </div>
          </div>
        </div>
        <div class="columns content">
          <div class="column is-2">
            <button
              class="upload-button button is-primary mt-6"
              @click="$router.push({name: 'Home'})"
            >
              <span>Takaisin</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
  </div>
</template>

<script>
import SectionHeader from '@/components/SectionHeader'
import {mapActions, mapGetters, mapMutations} from "vuex"
import {setAxiosAuthToken} from "@/utils"
import { useMeta } from 'vue-meta'
import Footer from '@/components/Footer'

let initial_errors = {
  non_field_errors: [],
}

export default {
  name: 'PricingPage.vue',
  components: {
    SectionHeader,
    Footer
  },
  mounted() {
    this.getProducts()
    window.scrollTo(0, 0)
    this.listDiscountVolumes()
  },
  setup () {
    useMeta({
      title: 'Hinnasto – Custom Fazer',
      description: 'Tutustu personoitujen tuotepakettien hinnastoon. Personoitu herkkupaketti yllättää vastaanottajan iloisesti. Sekä yritys- että kuluttaja-asiakkaille.'
    })
  },
  methods: {
    ...mapMutations([
      'setSpinnerState'
    ]),
    ...mapGetters({
      getOrderData: 'getOrderData'
    }),
    ...mapActions([
      'listDiscountVolumes',
      'login',
      'getProducts',
      'postFazerBar',
      'postMarianneBox',
      'postCustomCandyBox',
      'postFazerBox'
    ]),
    getFazerDiscounts() {
      return this.$store.getters.getVolumesDiscount(
        'blue'
      )
    },
    getDominoDiscounts() {
      return this.$store.getters.getVolumesDiscount(
        'domino'
      )
    },
    getMarianneDiscounts() {
      return this.$store.getters.getVolumesDiscount(
        'marianne'
      )
    },
    getFazerBarDiscounts() {
      return this.$store.getters.getVolumesDiscount(
        'bar'
      )
    },
    getCandyBoxDiscounts() {
      return this.$store.getters.getVolumesDiscount(
        'custom-candy-box'
      )
    },
    getCalendarDiscounts() {
      return this.$store.getters.getVolumesDiscount(
        'calendar-b2b'
      )
    },
    routerParamToProductId(param) {
      if (param == 'bar') {
        return 'bar'
      } else if (param == 'marianne') {
        return 'marianne'
      } else if (param == 'custom-candy-box') {
        return 'custom-candy-box'
      } else if (param == 'fazer') {
        return 'blue'
      }
    },
    getPostFunction(item) {
      const products = {
        'bar': this.postFazerBar,
        'marianne': this.postMarianneBox,
        'custom-candy-box': this.postCustomCandyBox,
        'fazer': this.postFazerBox,
      }
      return products[item]
    },
    goToEditor(item) {
      let self = this
      self.setSpinnerState(true)
      this.login({})
      .then(() => {
        let token = self.$store.state.postLoginData.token
        setAxiosAuthToken(token)
      })
        .then(() => {
          self.setSpinnerState(false)
          if (item == 'domino' || item == 'calendar-b2b') {
            self.$router.push({name: 'upload', params: {product: item}})
          } else {
            let data = {
              'product': this.$store.getters.getProductId(this.routerParamToProductId(item)),
              'type': 'ALL_TO_SAME_ADDRESS', // DEFAULT, it doesnt really mean anything yet, it will be configured on the next page
              'csv_status': 'NO_CSV_NEEDED', // DEFAULT, it doesnt really mean anything yet, it will be configured on the next page
              'draft': true
            }
            if (this.$store.state.order != null) {
              let order_id = this.$store.state.order.id
              if (order_id != undefined) {
                data['order_id'] = order_id
              }
            }
            this.getPostFunction(item)(data)
              .then(() => {
                if (item == 'fazer') {
                  self.$router.push({name: 'editor', params: {uuid: self.getOrderData().last_product}})
                } else {
                  self.$router.push({name: 'editor-tab', params: {uuid: self.getOrderData().last_product}})
                }
              })
          }
        })
        .catch(function (error) {
          if (!error.response) {
            // no response from server.. maybe network down?
            self.setSpinnerState(false)
            self.errors = Object.assign({}, initial_errors, {non_field_errors: [error.message]}) // combine initial and response
          } else {
            self.setSpinnerState(false)
            self.errors = Object.assign({}, initial_errors, error.response.data) // combine initial and response
          }
        })
    },
  }
}
</script>

<style lang="scss" scoped>

@import '../assets/sass/mybulma';

.container {
  //height: 88.2vh;
}

ol {
  counter-reset: item;
  margin-bottom: 2rem;
}

li {
  display: block;
  font-weight: bold;

  li{
    font-weight: normal;
  }
}

li:before {
  content: counters(item, '.') ' ';
  counter-increment: item;
  font-weight: bold;
}

.bulk-discount-box {
  background-color: white;
  border: 2px solid #ebeff5;
  .bulk-discount-title {
    font-family: gill-sans-nova;
    font-size: 22px;
    font-weight: bold;
  }
  .grey-background {
    background-color: #EBEFF5;
  }
  p {
    display: flow-root;
  }
  .bulk-discount-row {
    font-size: 0.9em;
  }
}

h1 {
  color: $primary !important;
  font-size: 45px !important
}

h2 {
  color: $primary !important;
  font-size: 30px !important;
}

h3 {
  color: $primary !important;
  font-size: 28px !important;
}

button {
  width: 250px;
  span {
    font-size: 20px;
  }
}

.content {
  padding: 20px;
}
</style>
