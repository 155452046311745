<template>
  <div class="editor" ref="editorRefWidth">
    <h1 class="mb-6">Pakkauksen muokkaaminen</h1>

    <div class="columns m-0 mb-5">
      <div class="column py-0">
        <p class="size16 has-text-centered">

        </p>
      </div>
    </div>

    <div class="columns m-0">
      <div class="column py-0">
        <p class="size16 has-text-left">Kalenteri</p>
      </div>

    </div>

    <div class="columns">
      <div class="column">

        
      </div>
    </div>

    <div class="columns is-mobile mb-0">
      <div class="column pb-0">
        <div id="editor-wrapper" ref="editorWrapper">
          <div class="column is-fullwidth">
            <div
              v-if="$refs.editorRefWidth"
              class="text-preview columns is-vcentered is-flex is-justify-content-center is-align-items-center is-mobile"
              id="text-box-wrapper-preview"
            >
            </div>
          </div>
          <div
            v-if="$refs.editorRefWidth"
            id="croppieBoundary"
            class="column is-12 px-0 pt-0"
          >
            <div
              :style="frameCss"
              class="frameDiv"
              alt="Calendar Frame"
            />
            <div
              :style="cellsCss"
              class="cellsDiv"
              alt="Calendar Window Cells"
            />
            <div
              :style="numberingCss"
              class="numberingDiv"
              alt="Numbering"
            />
            <div class="bubble-wrapper" v-if="this.product.image.label_count >= 1 && this.product.product.name == 'calendar'">
              <p class="bubble" @click="showModerationModal">Tarkista kuvan sisältö</p>
              <asModalDialog :show-ok="false" :show-cancel="false" heading-text="" ref="imgModModalRef">
                <p class="img-mod-text">
                  Automaattinen kuvantunnistus on havainnut, että kuvassa saattaa olla sisältöä, joka ei vastaa palvelun
                  tilausehtoja. Voit kuitenkin jatkaa tilauksen tekemistä normaalisti, sillä tarkistamme kuvamateriaalin
                  vielä tilauksen jälkeen. Mikäli kuvamateriaali on tilausehtojen mukainen, hyväksymme kalenterin tuotantoon.
                </p>
                <p class="img-mod-link mt-3"><a href="/rules" target="_blank">Lue lisää</a></p>
              </asModalDialog>              
            </div>

            <vue-croppie
              ref="croppieRef"
              :enableOrientation="true"
              :enableExif="true"
              :viewport="viewportWidthHeight"
              :boundary="viewportWidthHeight"
              :showZoomer="false"
              :enableResize="false"
              :minZoom="croppie_zoom_min"
              :maxZoom="croppie_zoom_max"
              @update="croppieUpdated"
            >
            </vue-croppie>
          </div>
        </div>
      </div>
    </div>
    <canvas-image
      ref="calendarCanvas"
      :image-width=croppieInitialWidth
      :image-height=croppieInitialHeight
      :options="{
        //backgroundColor: '#f4acbc',
        //color: '#503120',
        //font: fontFamily,
        //fontSize: fontSize,
        //lineHeight: lineHeight,
      }"
    />

    <div class="columns is-8 is-8-mobile is-offset-2-mobile is-offset-2">
      <div class="column is-8 is-8-mobile is-offset-2-mobile is-offset-2">
        <div class="bubble-wrapper-bad-quality" v-if="this.lowResolution">
          <p class="bubble-bad-quality">Kuvan resoluutio on matala, se saattaa tulostua epätarkkana.</p>
        </div>
      </div>
    </div>

    <div class="columns pb-0 mb-0">
      <div class="column is-12 pb-0 pt-0">
        <span class="is-block editor-top-span">Kohdista kuva raahaamalla sitä pakkauksen päällä. <br>Huom. punaiset apuviivat eivät tulostu kalenteriin.</span>
      </div>
    </div>
    <div class="columns border-bottom mb-0 is-hidden-tablet">
      <div class="column is-one-third-desktop is-12-mobile pt-0 pb-0">
        <div id="slider-wrapper-mobile" class="slider-wrapper">
          <input
            class="slider is-fullwidth is-large is-circle"
            type="range"
            step="0.0001"
            aria-label="zoom"
            v-bind:min="croppie_zoom_min"
            v-bind:max="croppie_zoom_max"
            @change="updateCroppieZoom"
            :value="croppie_zoom"
          />
        </div>
        <span class="is-block">Loitonna / lähennä kuvaa</span>
      </div>
    </div>
    <div class="columns mb-5 is-vcentered is-mobile is-multiline pt-0 border-bottom pt-0 mt-0">


      <div class="column is-one-third-desktop is-6-mobile border-right">
        <div class="is-clickable" @click="$refs.file.click()" tabindex="0" role="button" aria-label="Vaihda kuva" v-on:keyup.enter="$refs.file.click()">
          <span class="icon is-large is-clickable">
            <i class="fas fa-2x fa-file-image"></i>
          </span>
          <br />
          <input style="display: none" type="file" ref="file" @change="uploadPicture($event)" accept="image/*">
          <span class="m-t-md is-block">Vaihda kuva</span>
        </div>
      </div>
      <div class="column is-one-third-desktop is-hidden-mobile border-right">
        <div id="rotate-wrapper-desktop" class="rotate-wrapper is-clickable">
          <span class="icon is-large is-clickable" @click="rotate(90)" tabindex="0" role="button" aria-label="Käännä kuvaa" v-on:keyup.enter="rotate(90)">
            <i class="fas fa-2x fa-redo-alt"></i>
          </span>
          <br />
          <span class="m-t-md is-block">Käännä kuvaa</span>
        </div>
      </div>
      <div class="column is-6-mobile is-hidden-tablet" style="height: 111px;">
        <div id="rotate-wrapper-mobile" class="rotate-wrapper is-clickable">
          <span class="icon is-large is-clickable" @click="rotate(90)" tabindex="0" role="button" aria-label="Käännä kuvaa" v-on:keyup.enter="rotate(90)">
            <i class="fas fa-2x fa-redo-alt"></i>
          </span>
          <br />
          <span class="m-t-md is-block">Käännä kuvaa</span>
        </div>
      </div>
      <div class="column is-one-third-desktop is-hidden-mobile pt-0 pb-0">
        <div id="slider-wrapper-desktop" class="slider-wrapper">
          <input
            class="slider is-fullwidth is-large is-circle"
            type="range"
            step="0.0001"
            aria-label="zoom"
            v-bind:min="croppie_zoom_min"
            v-bind:max="croppie_zoom_max"
            @change="updateCroppieZoom"
            :value="croppie_zoom"
          />
        </div>
        <span class="is-block">Loitonna / lähennä kuvaa</span>
      </div>      
    </div>

    <div class="columns border-bottom">
      <div class="column is-12 is-12-mobile has-text-left has-text-centered-mobile" role="radiogroup">
        <label class="has-text-weight-bold mr-4 editor-span-left" tabindex="0" role="radio" aria-label="Kehys 1" v-on:keyup.enter="selected_frame='FRAME_ONE'">
          <input type="radio" id="frame_one" value="FRAME_ONE" v-model="selected_frame">
          <span class="graphical-radio"></span>
          Kehys 1
        </label>
        <label class="has-text-weight-bold mr-4 editor-span-left" tabindex="0" role="radio" aria-label="Kehys 2" v-on:keyup.enter="selected_frame='FRAME_TWO'">
          <input type="radio" id="frame_two" value="FRAME_TWO" v-model="selected_frame">
          <span class="graphical-radio"></span>
          Kehys 2
        </label><br>
        <label class="has-text-weight-bold mr-4 mt-5 is-inline-block editor-span-left" tabindex="0" role="radio" aria-label="Ei kehystä" v-on:keyup.enter="selected_frame='NO_FRAME'">
          <input type="radio" id="no_frame" value="NO_FRAME" v-model="selected_frame">
          <span class="graphical-radio"></span>
          Ei kehystä
        </label>
      </div>
    </div>

    <div class="columns">
      <div class="column is-4-tablet is-12-mobile has-text-left has-text-centered-mobile">
        <span class="editor-span-left">
          <span class="has-text-weight-bold has-text-left editor-span-left">Kappalemäärä</span>
        </span>
      </div>
      <div class="column is-5-tablet is-12-mobile has-text-left has-text-centered-mobile">
        <div class="amount-options">
          <div class="svg-wrapper" @click="decreaseAmount()" tabindex="0" role="button" v-on:keyup.enter="decreaseAmount()" :aria-label="'Vähennä oma kalenteri määrää'">
            <i class="fas fa-minus-circle"></i>
          </div>
          <div class="amount-input-wrapper control is-inline-flex">
            <input type="number" @keypress="preventNonNumericalInput(event)" min="0" @change="changedAmount()" v-model="product.amount" class="input has-text-centered" />
          </div>
          <div class="svg-wrapper" @click="increaseAmount()" tabindex="0" role="button" v-on:keyup.enter="increaseAmount()" :aria-label="'Lisää oma kalenteri määrää'">
            <i class="fas fa-plus-circle"></i>
          </div>
        </div>
      </div>
      <div class="column is-3-tablet is-12-mobile has-text-left has-text-centered-mobile" v-if="product.product != undefined && product.product.name == 'calendar'">
        <span class="editor-span-left">
          <span class="has-text-weight-bold has-text-left editor-span-left">{{getCalendarPricing()}}  € / kpl</span>
        </span>
      </div>
    </div>

    <div class="columns" v-if="product.product != undefined && product.product.name == 'calendar'">
      <div class="column is-4-tablet is-12-mobile has-text-left has-text-centered-mobile">
        <p class="mt-3" style="line-height: 0.8" v-if="calendarOrderAmount + this.product.amount == 20">
          <span style="color: red;">
            Tilauksen maksimimäärä on 20 kpl.
          </span>
        </p>
      </div>
    </div>

  </div>
</template>

<script>
import VueCroppieComponent from '@/components/helpers/VueCroppieComponent'
import getSize from 'element-size'
import asModalDialog from '@/components/helpers/asModalDialog'
import canvasImage from '@/components/canvasImage'
import inputNumberMixin from '@/components/helpers/inputNumberMixin.js'
import frame_one from '@/assets/img/frame1_fullsize.png'
import frame_two from '@/assets/img/frame2_fullsize.png'
import numbering from '@/assets/img/numbering_fullsize.png'
import cells from '@/assets/img/windows_editor.png'
import debounce from 'lodash/debounce'

import {mapMutations, mapGetters, mapActions} from 'vuex'

require('style-loader!../../node_modules/croppie/croppie.css')

export default {
  name: 'CalendarEditor',
  props: {
    productId: String
  },
  components: {
    'vue-croppie': VueCroppieComponent,
    asModalDialog,
    canvasImage,
  },
  mixins: [inputNumberMixin],  
  data() {
    return {
      croppieInitialWidth: 960,
      croppieInitialHeight: 260,
      croppie_ready: false,
      cropped: null,
      calendarEditorWidth: 506,
      calendarEditorHeight: 734,
      croppie_zoom: 1,
      croppie_zoom_min: 0.2,
      croppie_zoom_max: 2.5,
      product: {
        amount: 1,
        id: null,
        position: null,
        image: {
          image: null,
          image_croppied: null,
          croppie_points: null,
          croppie_zoom: 1,
          croppie_orientation: null,
        },
      },
      element_zoom: 0, // text preview element zoom
      Url: null,
      editorScale: 1,
      prevWidth: 0,
      currWidth: 0,
      leftCroppieMarginValue: 0,
      selected_frame: 'FRAME_ONE',
      resolution: null
    }
  },
  mounted() {
    this.initEditorState(this.productId)
    this.croppie_ready = true // prevend watch triggering too early prevend

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
      this.onResize()
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  watch: {
    'product.amount'() {
      if (this.product.product.name == 'calendar') {
        if (this.calendarOrderAmount + this.product.amount > 20) {
          this.product.amount = 20 - this.calendarOrderAmount
        }
      }
    }
  },
  computed: {
    viewportWidthHeight() {
      return { width: this.calendarEditorWidth * this.editorScale, height: this.calendarEditorHeight * this.editorScale }
    },
    editorHeightResizer() {
      if (this.$refs.editorWrapper != null) {
        return this.$refs.editorWrapper.clientHeight
      }
      return this.calendarEditorHeight
    },
    cellsCss() {
      return {
        'background-image': `url(${cells})`,
        'position': 'absolute',
        'background-size': 'cover',
        'z-index': 10,
        'width': `${this.calendarEditorWidth * this.editorScale}px`,
        'height': `${this.calendarEditorHeight * this.editorScale}px`,
        'pointer-events': 'none', // let croppie events go through
        'left': `${this.leftCroppieMarginValue}px`
      }
    },
    frameCss() {
      return {
        'background-image': `url(${this.getFrameUrl})`,
        'position': 'absolute',
        'background-size': 'cover',
        'z-index': 10,
        'width': `${this.calendarEditorWidth * this.editorScale}px`,
        'height': `${this.calendarEditorHeight * this.editorScale}px`,
        'pointer-events': 'none', // let croppie events go through
        'left': `${this.leftCroppieMarginValue}px`
      }
    },
    getFrameUrl() {
      if (this.selected_frame == 'FRAME_ONE') {
        return frame_one
      } else if (this.selected_frame == 'FRAME_TWO') {
        return frame_two
      } else {
        return ''
      }
    },
    numberingCss() {
      return {
        'background-image': `url(${numbering})`,
        'position': 'absolute',
        'background-size': 'cover',
        'z-index': 5,
        'width': `${this.calendarEditorWidth * this.editorScale}px`,
        'height': `${this.calendarEditorHeight * this.editorScale}px`,
        'pointer-events': 'none', // let croppie events go through
        'left': `${this.leftCroppieMarginValue}px`
      }
    },
    lowResolution() {
      if (this.resolution != null) {
        if (this.resolution['width'] < 1085 || this.resolution['height'] < 748) {
          return true
        }
      }
      return false
    },
    calendarOrderAmount() {
      let total = 0
      let self = this
      this.$store.getters.getOrderData.products.forEach(function(box) {
        if (box.product.name == 'calendar' && box.draft == false && box.id != self.product.id) {
          total += box.amount
        }
      })
      return total
    }
  },
  methods: {
    ...mapMutations([
      'setSpinnerState',
      'setCartSpinnerState',
      'updateProductAmount',
      'setNotificationActiveState'
    ]),
    ...mapGetters({
      getOrderData: 'getOrderData'
    }),
    ...mapActions([
      'postCalendar',
    ]),
    onResize() {
      this.currWidth = window.innerWidth
      if (this.prevWidth == 0 || this.currWidth != this.prevWidth) {
        if (this.$refs.editorRefWidth) {
          let editor = this.$refs.editorRefWidth
          let size = getSize(editor) // uncomment this to enable zoom support
          this.editorScale = size[0] / this.calendarEditorHeight
        }
        this.leftCroppieMarginValue = ($(".croppie-container").width() - $(".cr-boundary").width()) / 2
        this.$nextTick(() => {
          this.initEditorState(this.productId)
        })
        this.prevWidth = this.currWidth
      }
    },
    getImageResolution(imgUrl) {
      const img = new Image()
      img.src = imgUrl

      img.onload = () => {
        this.resolution = {
          width: img.width,
          height: img.height
        }
      }
    },
    initEditorState(productId) {
      let product = this.$store.getters.getProductById(productId)
      this.product = product
      this.selected_frame = this.product.image.frame
      if (this.selected_frame == null) {
        this.selected_frame = 'FRAME_ONE'
      }
      this.$nextTick().then(() => {
        this.$refs.croppieRef.refresh()
        this.bindCroppieImage()
        this.getImageResolution(this.product.image.image)
      })
    },
    async bindCroppieImage() {
      // we need 1 render cycle to change the parameters of vue-croppie
      // poins is stored as json string in backend
      const points = JSON.parse(this.product.image.croppie_points)
      if (this.product.image.croppie_zoom == null) {
        this.product.image.croppie_zoom = this.croppie_zoom
      }
      this.croppie = await this.$refs.croppieRef.bind({
        url: this.product.image.image,
        points: points,
        orientation: this.product.image.croppie_orientation,
        zoom: this.product.image.croppie_zoom,
        viewport: this.viewportWidthHeight,
        boundary: this.viewportWidthHeight,
      })

      this.$nextTick().then(() => {
        this.croppie_zoom = this.product.image.croppie_zoom
        this.croppie_zoom_min = Number(this.$refs.croppieRef.croppie.elements.zoomer.min)
        this.croppie_zoom_max = Number(this.$refs.croppieRef.croppie.elements.zoomer.max)

        this.leftCroppieMarginValue = ($(".croppie-container").width() - $(".cr-boundary").width()) / 2
      })
    },
    croppieUpdated(val) {
      // signal handler for croppie update events
      let croppie_state = this.$refs.croppieRef.get()
      if (parseFloat(this.croppie_zoom, 10) !== parseFloat(croppie_state.zoom, 10)) {
        this.croppie_zoom = croppie_state.zoom
      }
      this.product = {
        ...this.product,
        image: { ...this.product.image, croppie_zoom: croppie_state.zoom, croopie_points: croppie_state.points },
      }
    },
    result(output) {
      this.cropped = output
    },
    rotate(rotationAngle) {
      // Rotates the image
      this.$refs.croppieRef.rotate(rotationAngle)
    },
    orientationUpdated(event) {
      this.$nextTick().then(() => {
        this.$refs.croppieRef.refresh()
        this.bindCroppieImage()
      })
    },
    increaseAmount: debounce(function () {
      if (this.product.product.name != 'calendar' || (this.calendarOrderAmount + this.product.amount <= 20)) {
        this.setSpinnerState(true)
        this.product.amount += 1
        this.updateProductAmount({'product_id': this.product.id, 'amount': this.product.amount})
        this.setSpinnerState(false)
      }
    }, 100),
    decreaseAmount: debounce(function () {
      this.setSpinnerState(true)
      if (this.product.amount > 1) {
        this.product.amount -= 1
      }
      this.updateProductAmount({'product_id': this.product.id, 'amount': this.product.amount})
      this.setSpinnerState(false)
    }, 100),
    changedAmount() {
      if (this.product.product.name != 'calendar' || this.product.amount <= 20) {
        this.updateProductAmount({'product_id': this.product.id, 'amount': this.product.amount})
      }
    },
    updateBoxCroppieData(productObject) {
      return new Promise((resolve, reject) => {
        let options = {
          type: 'base64',
          size: 'original',
          format: 'jpeg',
          circle: false,
        }
        let croppieInst = this.$refs.croppieRef
        croppieInst.result(options, (cropped_image) => {
          productObject.image.image_croppied = cropped_image
          const { orientation, points, zoom } = croppieInst.get()
          productObject.image.croppie_orientation = orientation
          productObject.image.croppie_zoom = zoom
          productObject.image.croppie_points = JSON.stringify(points)
          resolve(productObject)
        })
      })
    },
    updateCroppieZoom(event) {
      this.$refs.croppieRef.setZoom(event.target.value)
    },   
    saveBox(productObject) {
      if (productObject == null) productObject = this.product
      return this.updateBoxCroppieData(productObject).then((updatedBox) => {
        updatedBox['draft'] = false
        updatedBox['frame'] = this.selected_frame
        return this.$store.dispatch('updateOrder',{'products': [updatedBox]})
      })
      .catch((error) => {
        console.log('network error', error)
      })
    },
    uploadPicture(event) {
      let order_id = null
      if (this.$store.state.order != null) {
        order_id = this.$store.state.order.id
      }
      this.setSpinnerState(true)
      this.setCartSpinnerState(true)
      let self = this
      let files = event.target.files || event.dataTransfer.files
      if (!files.length) return
      // upload picture and create the product
      let reader = new FileReader()
      reader.readAsDataURL(files[0])
      reader.onload = e => {
        let imageData = {
          'image': e.target.result,
          'product': this.$store.getters.getProductId(this.product.product.name),
          'draft': true,
          'calendar': this.productId
        }
        if (order_id != null) {
          imageData['order_id'] = order_id
        }
        this.postCalendar(imageData).then(() => {
          self.setSpinnerState(false)
          self.setCartSpinnerState(false)
          self.$router.go() // refresh page
        })
      }
    },
    showModerationModal() {
      this.$refs.imgModModalRef.showModal().then(
        () => {

        }
      ).catch(
        () => {

        }
      )
    },
    getCalendarPricing() {
      let price = this.$store.getters.getVolumesDiscount(
        'calendar'
      )
      return parseFloat(price[0].price).toFixed(2)
    }
  }
}
</script>

<style scoped lang="scss">
@import '../assets/sass/mybulma';

$total-width: 1110px;
$croppie-width: 555px;
$croppie-height: 555px;
$croppie-right-off: 0px;
$original-width: 4 00px;
$switch-background: #726f6f;
$switch-background-active: #726f6f;

#croppieBoundary :v-deep(.cr-boundary) {
  margin: 0 !important;
}

#croppieBoundary {
  position: relative;

  .landscape {
    margin-left: auto;
    margin-right: auto;
  }

  &.portrait {
  }
}

.vertical-text {
  writing-mode: vertical-rl;
  text-orientation: mixed;
}

.editor {
  @media (max-width: 500px) {
    margin-top: 20px;
  }
  .top-editor-section p.underline {
    @media (max-width: 400px) {
      font-size: 12px !important;
    }
  }
  span {
    font-size: 18px;
    @media (max-width: 400px) {
      font-size: 12px;
    }
    font-family: gill-sans-nova;
    color: $primary;
  }

  label {
    font-size: 18px;
    font-family: gill-sans-nova;
    color: $primary;
  }

  .switch[type='checkbox'].is-rounded + label::before {
    border: 1px solid grey;
  }

  label.togglebold {
    color: $primary;
    font-weight: bold;
  }

  .amount-options {
    .svg-wrapper {
      display: inline-block;

      svg {
        font-size: 25px;
        top: 3px;
        position: relative;
        color: $primary;
      }

      svg:hover {
        cursor: pointer;
        color: $primary;
      }
    }

    span {
      width: 70px;
      display: inline-block;
      border: 1px solid gray;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  #rotate-wrapper {
    svg.fa-redo-alt {
      display: block;
      margin-left: auto;
      margin-right: auto;
      color: $primary;
    }
  }

  #editor-title {
    text-align: left;
  }

  .is-checkradio[type='radio']:hover:not([disabled]) + label::before {
    border: 0.1rem solid $secondary !important;
  }

  .is-checkradio[type='radio']:checked + label::after {
    background-color: $secondary !important;

    &:hover {
      border: 0.1rem solid $secondary !important;
    }
  }

  #toggle-orientation {
    .left-label {
      @media screen and (min-width: 1720px) {
        margin-right: 50px;
      }
      margin-right: 20px;
      @media screen and (max-width: 500px) {
        margin-right: 10px;
        font-size: 12px !important;
      }
    }

    .right-label {
      padding-left: 4rem;
      @media screen and (min-width: 1720px) {
        padding-left: 6rem;
      }
      @media screen and (max-width: 500px) {
        padding-left: 4rem;
        font-size: 12px !important;
      }
    }
  }
}

#slider-wrapper {
  margin-right: 20px;
  @media screen and (max-width: 500px) {
    margin-right: 0px;
  }
}

.slider {
  pointer-events: none;
}

.slider::-webkit-slider-thumb {
  pointer-events: auto;
}
.slider::-moz-range-track {
  pointer-events: auto;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.amount-input-wrapper {
  width: 80px;
  margin-left: 10px;
  margin-right: 10px;
}

.editor-top-span {
  border-bottom: 1px solid $primary;
  padding-bottom: 10px;
}

.editor-span-left {
  padding-left: 20px;
  padding-bottom: 20px;
  @media (max-width: 1230px) {
    font-size: 15px !important;
  }
  @media (max-width: 768px) {
    padding-left: 0px !important;
  }
}

.border-bottom {
  margin-left: 1px;
  margin-right: 1px;
  border-bottom: 1px solid $primary;
  @media (max-width: 768px) {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

.border-right {
  border-right: 1px solid $primary;
  height: 111px;
  /**
  @media (max-width: 768px) {
    border-bottom: 1px solid $primary;
  }
   */
}

input[type="radio"] {
  display: none;
}

.graphical-radio {
  background: white;
  display: inline-block;
  border-radius: 100%;
  border: 1px solid #15256B;
  margin-right: 6px;
  width: 25px;
  height: 25px;
  @media (max-width: 768px) {
    width: 15px;
    height: 15px;
  }
  position: relative;
  top: 4px;
}

input[type="radio"]:checked + .graphical-radio {
  background: #15256B;
}

.bubble-wrapper {
  z-index: 11;
  position: absolute;
  width: 50%;
  bottom: 200px;
  right: 250px;
  @media screen and (max-width: 767px) {
    right: 25%;
  }  
}

.bubble-wrapper-bad-quality {
  .bubble-bad-quality {
    padding: 5px 10px 5px 10px;
    background: #FFFFFF;
    border: 1px solid $fazer-red;
    font-size: 15px;
    color: $fazer-red;
  }
}

.bubble {
  position: absolute;
  padding: 5px 10px 5px 10px;
  background: #FFFFFF;
  border: 1px solid $fazer-red;
  font-size: 15px;
  color: $fazer-red;
  top: -25px;
  right: 60px;
  text-decoration: underline;
  cursor: pointer;
  @media only screen and (min-width: 1704px) {
    right: 63px;
  }
  @media screen and (max-width: 1215px) and (min-width: 768px) {
    right: 44px;
    font-size: 12px;
  }

  .img-mod-text {
    font-size: 20px;
  }

  .img-mod-link {
    color: #000000;
  }
}

.bubble:after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 15px 15px 0;
  border-color: #FFFFFF transparent;
  display: block;
  width: 0;
  z-index: 1;
  bottom: -15px;
  left: 55px;
  @media screen and (max-width: 1215px) {
    left: 37px;
  }
}

.bubble:before
{
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 15px 15px 0;
  border-color: $fazer-red transparent;
  display: block;
  width: 0;
  z-index: 0;
  bottom: -16px;
  left: 55px;
  @media screen and (max-width: 1215px) {
    left: 37px;
  }
}

.croppie-container .cr-slider-wrap {
  margin: none; // Fixes bubble arrow bottom border
}

</style>