<template>
  <div class="editor" ref="fazerEditorRefWidth">
    <asModalDialog
      heading-text="3d esikatselu"
      :show-ok="false"
      :show-cancel="false"
      width="90%"
      min-width="500px"
      ref="previewModal"
      :visibility="threeDeeRenderReady"
    >
      <ThreeDPreviewMultipleObjects
          :data="threeDdataSet"
          v-on:renderready="threeDeerenderReady()"
      />
    </asModalDialog>
    <CustomCandyBoxCanvas
      ref="customCandyBoxCanvas"
      :canvasText="product.text"
      :canvasTitle="product.title"
      :canvasSignature="product.signature"
      :fontStyle="{ 'font-family': fontFamily }"
      :bgColor="product.background_color || '#44BDC7'"
      :productlogoUrl="product.croppied_logo_url"
      style="display: none"
    />

    <div class="columns mt-6 is-mobile top-editor-section">
      <div class="column is-2-tablet is-3-mobile">
        <div class="add-logo" @click="changeLogo" tabindex="0" role="button" v-on:keyup.enter="changeLogo">
          <i class="fas fa-star clone-icon"></i>
          <p class="underline">Aseta logo</p>
        </div>
        <asModalDialog :show-ok="false" :show-cancel="false" heading-text="Aseta Logo" ref="logoEditorModal" min-width="700px">
          <logo-editor
            :mutation="'partialUpdateCustomCandyBox'"
            :metadata="{
              // real size (93x50)
              desktop: {
                viewport: {
                  // *4
                  width: 372,
                  height: 200,
                },
              },
              noDesktop: {
                viewport: {
                  // *2
                  width: 186,
                  height: 100,
                },
              },
            }"
            :options="{
              size:{
                // real size 314x201
                width: 628,
                height: 402
              }
            }"
          />
        </asModalDialog>
      </div>

      <div class="column is-2-tablet is-3-mobile">
        <div
          class="remove-logo"
          @click="openRemoveModalLogo"
          :aria-disabled=!product.original_logo_url
          :tabindex="product.original_logo_url ? '0' : '-1'"
          role="button"
          v-on:keyup.enter="openRemoveModalLogo"
          v-bind:class="{ 'fa-disabled': !product.original_logo_url }"
        >
          <i class="fas fa-trash-alt trash-icon"></i>
          <p class="underline">Poista logo</p>
        </div>
        <asModalDialog ref="deleteModalLogo" heading-text="Poista logo">
          <p>Haluatko varmasti poistaa logon?</p>
        </asModalDialog>
      </div>

      <!--div class="column is-2-tablet is-3-mobile">
        <div class="remove-logo" @click="cloneBox()">
          <i class="fas fa-clone"></i>
          <p class="underline">Tee kopio</p>
        </div>
      </div-->

      <div class="column is-offset-4-tablet is-4-tablet is-3-mobile">
        <div class="remove-logo" @click="previewModal()" tabindex="0" role="button" v-on:keyup.enter="previewModal">
          <i class="fas fa-2x fa-cube"></i>
          <p class="underline">Katso 3D-kuva valmiista tuotteesta</p>
        </div>
      </div>
    </div>

    <div class="columns is-mobile mt-0 mb-0">
      <div class="column">
        <div
          id="front-editor-wrapper"
          v-show="isCover"
          ref="editorWrapper"
          class="editor-wrapper"
          :style="{ backgroundColor: product.background_color || '#44BDC7' }"
        >
          <div class="column is-fullwidth is-flex is-justify-content-center" style="height: 100%">
            <div
              v-if="$refs.fazerEditorRefWidth"
              class="text-preview columns is-vcentered is-flex is-justify-content-start is-align-items-center is-mobile is-flex-direction-column"
              id="front-text-box-wrapper-preview"
            >
              <div v-if="product.croppied_logo_url != null && product.croppied_logo_url != ''" class="logo-uploaded-wrapper">
                <img alt="Custom Candy Box Logo" class="logo-uploaded" :src="product.croppied_logo_url" />
              </div>
              <div v-else class="logo-upload is-align-items-center is-flex">
                <div class="logo-icon"></div>
              </div>
              <p v-html="title" class="is-primary" id="title-box-preview" :style="textPreviewCss"></p>
            </div>
          </div>
        </div>
        <div
          id="back-editor-wrapper"
          v-show="!isCover"
          ref="editorWrapper"
          class="editor-wrapper"
          :style="{ backgroundColor: product.background_color || '#44BDC7' }"
        >
          <div class="column is-fullwidth is-flex is-justify-content-center back-editor-wrapper-content">
            <div
              v-if="$refs.fazerEditorRefWidth"
              class="text-preview text-preview-content columns is-vcentered is-mobile"
              id="text-box-wrapper-preview"
            >
              <div>
                <p v-html="text" class="is-primary first-para" :style="textPreviewCss"></p>
                <p v-html="signature" class="is-primary second-para" :style="textPreviewCss"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns mt-6 is-multiline border-bottom" style="position: relative">
      <p class="column has-text-left is-hidden-tablet">
        <span class="has-text-weight-bold editor-span-left">Pakkauksen teksti</span>
      </p>
      <div class="column is-6 has-text-left">
        <p><span class="has-text-weight-bold has-text-left editor-span-left">Näytä</span></p>
        <div class="is-hidden-mobile" v-on:keyup.enter="isCover=!isCover">
          <div id="toggle-orientation" class="field is-flex is-align-items-center gap-17" tabindex="0" role="button">
            <label for="switchRoundedDefault" class="left-label" style="padding-left: 20px">Kortti</label>

            <input
              id="switchRoundedDefault"
              type="checkbox"
              v-model="isCover"
              name="switchRoundedDefault"
              class="switch is-rounded"
              checked="checked"
              tabindex="-1"
            />

            <label for="switchRoundedDefault" class="right-label is-flex">Pakkaus</label>
          </div>
        </div>
        <div class="is-hidden-tablet" v-on:keyup.enter="isCover=!isCover">
          <div id="toggle-orientation" class="field is-flex is-align-items-center gap-17" tabindex="0" role="button">
            <label for="switchRoundedDefault" class="left-label">Kortti</label>

            <input
              id="switchRoundedDefault"
              type="checkbox"
              v-model="isCover"
              name="switchRoundedDefault"
              class="switch is-rounded"
              checked="checked"
              tabindex="-1"
            />

            <label for="switchRoundedDefault" class="right-label is-flex">Pakkaus</label>
          </div>
        </div>
      </div>
      <div class="column is-6" tabindex="0" role="button" v-on:keyup.enter="showBgColorPicker=true">
        <p><span class="has-text-weight-bold has-text-left">Muokkaa väriä</span></p>
        <div class="bg-color-picker-wrapper" v-if="showBgColorPicker" v-on:keyup.escape="showBgColorPicker=false">
          <ColorPicker
            :color="product.background_color || '#44BDC7'"
            @color-change="updateBgColor"
            :visible-formats="['hex']"
            alpha-channel="hide"
          >
          </ColorPicker>
          <ColorPickerFooter
            :bgColor="product.background_color || '#44BDC7'"
            @cp-confirmButton="changeBgColor()"
            @cp-cancelButton="showBgColorPicker = false"
          />
        </div>
        <a
          class="is-flex is-flex-direction-column is-align-items-center editor-text-icon"
          @click="showBgColorPicker = !showBgColorPicker"
        >
          <span
            class="editor-action-text color-span"
            :style="{ backgroundColor: product.background_color || '#44BDC7' }"
          ></span>
        </a>
      </div>
    </div>

    <div class="columns mt-6 is-multiline border-bottom" style="position: relative">
      <div class="font-selection">
        <div class="dropdown is-right" :class="{ 'is-active': fontFamilyMenuActive }">
          <div class="dropdown-trigger">
            <button
              class="button"
              aria-haspopup="true"
              aria-controls="dropdown-menu"
              @click="openFontMenu"
            >
              <span>Vaihda fontti</span>
              <span class="icon is-medium-important"> <a class="fas fa-font"></a><br /> </span>
            </button>
          </div>
          <div class="dropdown-menu" id="dropdown-menu" role="menu" ref="menuList" @keydown="handleKeyDownFontMenu">
            <div class="dropdown-content">
              <a
                ref="firstFontMenuItem"
                class="dropdown-item"
                style="font-family: 'Gotham Black'"
                :class="{ 'is-active': tmpCandyBoxFont === 'F_GOTHAM' }"
                @click="tmpCandyBoxFont = 'F_GOTHAM'"
                tabindex="0" role="menuitem"
                v-on:keydown.enter="tmpCandyBoxFont = 'F_GOTHAM'; changeFont()"
              >
                Gotham Black
              </a>
              <a
                class="dropdown-item"
                style="font-family: 'Tartine Script Black'"
                :class="{ 'is-active': tmpCandyBoxFont === 'F_TARTINE' }"
                @click="tmpCandyBoxFont = 'F_TARTINE'"
                tabindex="0" role="menuitem"
                v-on:keydown.enter="tmpCandyBoxFont = 'F_TARTINE'; changeFont()"
              >
                Tartine Script
              </a>
              <a
                class="dropdown-item"
                style="font-family: 'Praho Pro'"
                :class="{ 'is-active': tmpCandyBoxFont === 'F_PARAHO' }"
                @click="tmpCandyBoxFont = 'F_PARAHO'"
                tabindex="0" role="menuitem"
                v-on:keydown.enter="tmpCandyBoxFont = 'F_PARAHO'; changeFont()"
              >
                Praho Pro
              </a>
              <a
                class="dropdown-item"
                style="font-family: 'myriadpro-cond'"
                :class="{ 'is-active': tmpCandyBoxFont === 'F_MYRIADPRO' }"
                @click="tmpCandyBoxFont = 'F_MYRIADPRO'"
                tabindex="0" role="menuitem"
                v-on:keydown.enter="tmpCandyBoxFont = 'F_MYRIADPRO'; changeFont()"
              >
                Myriadpro Cond
              </a>
              <a
                class="dropdown-item"
                style="font-family: 'helvetica-neue'"
                :class="{ 'is-active': tmpCandyBoxFont === 'F_HELVETICA' }"
                @click="tmpCandyBoxFont = 'F_HELVETICA'"
                tabindex="0" role="menuitem"
                v-on:keydown.enter="tmpCandyBoxFont = 'F_HELVETICA'; changeFont()"
              >
                Helvetica Neue
              </a>
              <div class="font-selection-buttons-wrapper">
                <button class="mt-3 mr-1 button is-primary" @click="changeFont()">
                  <span>Valitse fontti</span>
                </button>
                <button class="mt-3 mr-1 button is-primary" ref="lastFontMenuItem" @click="fontFamilyMenuActive = !fontFamilyMenuActive">
                  <span>Peruuta</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-one-third is-hidden-mobile has-text-left">
        <p><span class="has-text-weight-bold has-text-left editor-span-left">Pakkauksen teksti</span></p>
        <p class="mb-3"><span class="has-text-weight-bold has-text-left editor-span-left">Etukansi / otsikko</span></p>
      </div>
      <div class="column is-two-thirds is-hidden-mobile">
        <TextEditor
          :notext="false"
          :maxRows="1"
          name="text-editor-title-desktop"
          :fontStyle="{ 'font-family': fontFamily }"
          @inputtextarea="inputTitle"
          :maxCharactersPerRow="16"
          :producttext="product.title"
          ref="titleEditor"
          @click="toggleBox"
        >
        </TextEditor>
        <span class="red-color" v-if="titleEmpty">Pakollinen kenttä</span>
      </div>
      <div class="column is-12 is-hidden-tablet pt-0 pb-0 mb-3">
        <p class="has-text-left"><span class="has-text-weight-bold editor-span-left">Pakkauksen teksti</span></p>
        <p class="mb-3 has-text-left"><span class="has-text-weight-bold editor-span-left">Etukansi / otsikko</span></p>
        <TextEditor
          :notext="false"
          :maxRows="1"
          name="text-editor-title-mobile"
          :fontStyle="{ 'font-family': fontFamily }"
          @inputtextarea="inputTitle"
          :maxCharactersPerRow="16"
          :producttext="product.title"
          ref="titleEditor"
          @click="toggleBox"
        >
        </TextEditor>
        <span class="red-color" v-if="titleEmpty">Pakollinen kenttä</span>
      </div>
    </div>
    <div class="columns mt-6 is-multiline border-bottom" style="position: relative">
      <span class="has-text-weight-bold has-text-left is-hidden-tablet editor-span-left">Kortti / viesti</span>

      <div class="field is-hidden-tablet is-pulled-left ml-2 pt-0 pb-0">
        <input
          class="is-checkradio is-secondary"
          id="noTextRadioMobile"
          type="checkbox"
          name="noTextRadioMobile"
          v-model="product.notext"
          @click="toggleNoText()"
        />
        <label class="checkradio-label" for="noTextRadioMobile" tabindex="0" role="button" v-on:keyup.enter="toggleNoText">Ei tekstiä</label>
      </div>
      <div class="column is-one-third has-text-left is-hidden-mobile">
        <span class="has-text-weight-bold has-text-left editor-span-left">Kortti / viesti</span>
        <div class="field mt-5 editor-span-left">
          <input
            class="is-checkradio is-secondary"
            id="noTextRadioDesktop"
            type="checkbox"
            name="noTextRadioDesktop"
            v-model="product.notext"
            @click="toggleNoText()"
          />
          <label for="noTextRadioDesktop" tabindex="0" role="button" v-on:keyup.enter="toggleNoText">Ei tekstiä</label>
        </div>
      </div>
      <div class="column is-two-thirds is-hidden-mobile">
        <TextEditor
          :notext="product.notext"
          :maxRows="6"
          name="text-editor-main-desktop"
          :fontStyle="{ 'font-family': fontFamily, height: '130px !important' }"
          @inputtextarea="inputTextarea"
          :maxCharactersPerRow="50"
          :producttext="product.text"
          ref="textEditor"
          @click="toggleCard"
        >
        </TextEditor>
        <span class="red-color" v-if="textEmpty"
          >Pakollinen kenttä (Jos haluat jättää tyhjäksi, valitse "Ei tekstiä")</span
        >
      </div>
      <div class="column is-12 is-hidden-tablet pt-0 pb-0">
        <TextEditor
          :notext="product.notext"
          :maxRows="6"
          name="text-editor-main-mobile"
          :fontStyle="{ 'font-family': fontFamily, height: '130px !important' }"
          @inputtextarea="inputTextarea"
          :maxCharactersPerRow="50"
          :producttext="product.text"
          ref="textEditor"
          @click="toggleCard"
        >
        </TextEditor>
        <span class="red-color" v-if="textEmpty"
          >Pakollinen kenttä (Jos haluat jättää tyhjäksi, valitse "Ei tekstiä")</span
        >
      </div>
    </div>
    <div class="columns mt-6 is-multiline border-bottom" style="position: relative">
      <span class="has-text-weight-bold has-text-left is-hidden-tablet editor-span-left">Kortti / allekirjoitus</span>
      <div class="field is-hidden-tablet is-pulled-left ml-2 pt-0 pb-0">
        <input
          class="is-checkradio is-secondary"
          id="noSignatureRadioMobile"
          type="checkbox"
          name="noSignatureRadioMobile"
          v-model="product.nosignature"
          @click="toggleNoSignature()"
        />
        <label class="checkradio-label" for="noSignatureRadioMobile" tabindex="0" role="button" v-on:keyup.enter="toggleNoSignature">Ei tekstiä</label>
      </div>
      <div class="column is-one-third has-text-left is-hidden-mobile">
        <span class="has-text-weight-bold has-text-left editor-span-left">Kortti / allekirjoitus</span>
        <div class="field mt-5 editor-span-left">
          <input
            class="is-checkradio is-secondary"
            id="noSignatureRadioDesktop"
            type="checkbox"
            name="noSignatureRadioDesktop"
            v-model="product.nosignature"
            @click="toggleNoSignature()"
          />
          <label for="noSignatureRadioDesktop" tabindex="0" role="button" v-on:keyup.enter="toggleNoSignature">Ei tekstiä</label>
        </div>
      </div>
      <div class="column is-two-thirds is-hidden-mobile">
        <TextEditor
          :notext="product.nosignature"
          :maxRows="1"
          name="text-editor-signature-desktop"
          :fontStyle="{ 'font-family': fontFamily }"
          @inputtextarea="inputSignature"
          :maxCharactersPerRow="30"
          :producttext="product.signature"
          ref="signatureEditor"
          @click="toggleCard"
        >
        </TextEditor>
        <span class="red-color" v-if="signatureEmpty"
          >Pakollinen kenttä (Jos haluat jättää tyhjäksi, valitse "Ei tekstiä")</span
        >
      </div>
      <div class="column is-12 is-hidden-tablet pt-0 pb-0 mb-3">
        <TextEditor
          :notext="product.nosignature"
          :maxRows="1"
          name="text-editor-signature-mobile"
          :fontStyle="{ 'font-family': fontFamily }"
          @inputtextarea="inputSignature"
          :maxCharactersPerRow="30"
          :producttext="product.signature"
          ref="signatureEditor"
          @click="toggleCard"
        >
        </TextEditor>
        <span class="red-color" v-if="signatureEmpty"
          >Pakollinen kenttä (Jos haluat jättää tyhjäksi, valitse "Ei tekstiä")</span
        >
      </div>
    </div>

    <div class="columns is-mobile is-flex is-flex-wrap-wrap" style="margin-left: 1px; margin-right: 1px">
      <div class="column is-4 is-hidden-mobile has-text-left">
        <span class="has-text-weight-bold has-text-left editor-span-left">Kappalemäärä</span>
      </div>
      <div class="column is-3 is-hidden-tablet has-text-left">
        <span class="has-text-weight-bold has-text-left editor-span-left">Kappalemäärä</span>
      </div>
      <div class="column is-4 is-hidden-mobile is-offset-2-mobile has-text-left">
        <div class="amount-options">
          <div class="svg-wrapper" @click="decreaseAmount()" tabindex="0" role="button" v-on:keyup.enter="decreaseAmount()" :aria-label="'Vähennä karkkipaketti määrää'">
            <i class="fas fa-minus-circle"></i>
          </div>
          <div class="amount-input-wrapper control is-inline-flex">
            <input
              type="number"
              @keypress="preventNonNumericalInput(event)"
              min="0"
              @change="changedAmount()"
              v-model.number="product.amount"
              class="input has-text-centered"
            />
          </div>
          <div class="svg-wrapper" @click="increaseAmount()" tabindex="0" role="button" v-on:keyup.enter="increaseAmount()" :aria-label="'Vähennä karkkipaketti määrää'">
            <i class="fas fa-plus-circle"></i>
          </div>
        </div>
      </div>
      <div class="column is-8 is-hidden-tablet has-text-right">
        <div class="amount-options">
          <div class="svg-wrapper" @click="decreaseAmount()" tabindex="0" role="button" v-on:keyup.enter="decreaseAmount()" :aria-label="'Vähennä karkkipaketti määrää'">
            <i class="fas fa-minus-circle"></i>
          </div>
          <div class="amount-input-wrapper control is-inline-flex">
            <input
              type="number"
              @keypress="preventNonNumericalInput(event)"
              min="0"
              @change="changedAmount()"
              onkeypress="preventNonNumericalInput(event)"
              v-model.number="product.amount"
              class="input has-text-centered"
            />
          </div>
          <div class="svg-wrapper" @click="increaseAmount()" abindex="0" role="button" v-on:keyup.enter="increaseAmount()" :aria-label="'Vähennä karkkipaketti määrää'">
            <i class="fas fa-plus-circle"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="columns mt-3 is-mobile" style="position: relative" v-if="flavors.length > 0">
      <div class="column is-12">
        <div id="flavour-selector">
          <div class="columns is-multiline is-flex">
            <div
              v-for="(flavor, idx) in flavors"
              class="column is-3 is-6-mobile"
              v-bind:class="{ selected: product.flavor == flavor.id }"
              tabindex="0" role="menuitem" v-on:keyup.enter="product.flavor = flavor.id"
            >
              <label>
                <input type="radio" v-model="product.flavor" :value="flavor.id" tabindex="-1"/>
                <img :src="flavor.thumbnail" :alt="'valitse ' + flavor.flavor + ' tuote'" />
                <p class="has-text-centered pb-2">{{ flavor.flavor }}</p>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextEditor from '@/components/TextEditor'
import getSize from 'element-size'
import LogoEditor from '@/components/LogoEditor'
import debounce from 'lodash/debounce'
import asModalDialog from '@/components/helpers/asModalDialog'
import ColorPickerFooter from '@/components/ColorPickerFooter'
import ThreeDPreviewMultipleObjects from '@/components/3d/ThreeDPreviewMultipleObjects'
import CustomCandyBoxCanvas from '@/components/CustomCandyBoxCanvas.vue'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import { ColorPicker } from 'vue-accessible-color-picker'
import inputNumberMixin from '@/components/helpers/inputNumberMixin.js'

let fontMapping = {
  F_GOTHAM: ['Gotham Black', '22px', '30px'],
  F_TARTINE: ['Tartine Script Black', '24px', '30px'],
  F_PARAHO: ['Praho Pro', '24px', '30px'],
  F_MYRIADPRO: ['myriadpro-cond', '24px', '30px'],
  F_HELVETICA: ['helvetica-neue', '30px', '30px'],
}

export default {
  name: 'CustomCandyBoxEditor',
  props: {
    productId: String,
  },
  components: {
    TextEditor,
    asModalDialog,
    ThreeDPreviewMultipleObjects,
    CustomCandyBoxCanvas,
    ColorPicker,
    LogoEditor,
    ColorPickerFooter,
  },
  mixins: [inputNumberMixin],
  data() {
    return {
      threeDdata: {
        name: 'Candybox',
        uri: `${process.env.BASE_URL}3d/candybox.glb`,
        objects: [],
        animations: ['FullyOpen', 'PartiallyOpen', 'Closed'],
      },
      textureUri: `${process.env.BASE_URL}3d/candybox.glb`,
      marianneEditorWidth: 657,
      marianneEditorHeight: 263,
      temporalText: '',
      isCover: true,
      product: {
        notext: false,
        nosignature: false,
        amount: 1,
        id: null,
        text: '',
        title: '',
        signature: '',
        front_side_picture: '',
        back_side_picture: '',
        flavor: 5,
        font: 'F_GOTHAM',
        background_color: '#44BDC7',
      },
      element_zoom: 0, // text preview element zoom
      Url: null,
      textAsImageUrl: '',
      backAsImageUrl: '',
      editorScale: 1,
      titleEmpty: false,
      textEmpty: false,
      signatureEmpty: false,
      flavors: [],
      showBgColorPicker: false,
      tmpBgColor: '#44BDC7',
      fontFamilyMenuActive: false,
      tmpCandyBoxFont: 'F_GOTHAM',
      threeDeeRenderReady: false
    }
  },

  mounted() {
    this.initEditorState(this.productId)
    this.flavors = this.product.product.flavors
    if (this.flavors.length == 0) {
      // default to empty flavor
      this.product.flavor = null
    }
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
      this.handleTextPreviewResize()
      this.onResize()
    })
    if(!this.product.draft) {
      if(!this.product.signature && !this.product.nosignature) this.toggleNoSignature()
      if(!this.product.text && !this.product.notext) this.toggleNoText()
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  computed: {
    threeDdataSet() {
      return { ...this.threeDdata, objects: [this.textImageTexture, this.backImageTexture] }
    },
    textImageTexture() {
      return {
        id: 1,
        objectName: 'bottom',
        name: 'Bottom',
        width: 1257,
        height: 2118,
        textures: [
          {
            uri: this.textAsImageUrl,
            width: 1257,
            height: 2118,
            rotation: 0,
          },
        ],
      }
    },
    backImageTexture() {
      return {
        id: 2,
        name: 'Top',
        objectName: 'top',
        width: 1257,
        height: 2118,
        textures: [
          {
            uri: this.backAsImageUrl,
            width: 1257,
            height: 2118,
            rotation: 0,
          },
        ],
      }
    },
    text() {
      if (this.product.text == '' && !this.product.notext) {
        return 'Viesti (max 300 merkkiä)'
      } else {
        return this.product.text.replace(/\n/g, '<br>')
      }
    },
    signature() {
      if (this.product.signature == '' && !this.product.nosignature) {
        return 'Allekirjoitus (max 30 merkkiä)'
      } else {
        return this.product.signature.replace(/\n/g, '<br>')
      }
    },
    title() {
      if (this.product.title == '' && !this.product.notext) {
        return 'Otsikko (max 16)'
      } else {
        return this.product.title.replace(/\n/g, '<br>')
      }
    },
    viewportWidthHeight() {
      return {
        width: this.marianneEditorWidth * this.editorScale,
        height: this.marianneEditorHeight * this.editorScale,
      }
    },
    backPreviewCss() {
      let styles = {}
      styles = Object.assign(styles, {
        'font-family': 'Karl Fazer',
        'font-size': '24pt',
        color: '#c69b67 !important',
        position: 'relative',
      })
      return Object.assign(styles, {
        height: `${this.marianneEditorHeight * this.editorScale}px`,
        width: `${this.marianneEditorWidth * this.editorScale}px`,
      })
    },
    sideFontPreviewCss() {
      let styles = {}
      styles = Object.assign(styles, {
        transform: 'scale(' + this.element_zoom + ')',
        'max-width': this.marianneEditorWidth + 'px',
        color: '#c69b67 !important',
        'font-size': '40pt',
        'white-space': 'nowrap'
      })
      return styles
    },
    textPreviewCss() {
      let styles = {}

      styles = Object.assign(styles, {
        'font-family': this.fontFamily,
        'font-size': this.fontSize,
        'line-height': this.lineHeight,
      })

      if (this.isLandscape) {
        return Object.assign(styles, {
          height: `${this.dominoPreviewHeight * this.editorScale}px`,
          width: `${this.dominoPreviewWidth * this.editorScale}px`,
        })
      } else {
        return Object.assign(styles, {
          height: `${this.dominoPreviewWidth * this.editorScale}px`,
          width: `${this.dominoPreviewHeight * this.editorScale}px`,
        })
      }
    },
    textareaCharacters() {
      return this.product.text.length
    },
    fontFamily() {
      if (this.product.font && fontMapping[this.product.font][0]) {
        return fontMapping[this.product.font][0]
      } else {
        return this.font
      }
    },
    fontSize() {
      if (this.product.font && fontMapping[this.product.font][1]) {
        return fontMapping[this.product.font][1]
      } else {
        return this.font
      }
    },

    lineHeight() {
      if (this.product.font && fontMapping[this.product.font][2]) {
        return fontMapping[this.product.font][2]
      } else {
        return this.font
      }
    },
    editorHeightResizer() {
      if (this.$refs.editorWrapper != null) {
        return this.$refs.editorWrapper.clientHeight
      }
      return this.marianneEditorHeight
    }
  },
  watch: {
    productId: function (newId, oldId) {
      if (newId === oldId) {
        return
      }
      this.setSpinnerState(true)
      const previousBox = JSON.parse(JSON.stringify(this.product)) // copy of old box, drops proxies
      this.saveBox(previousBox)
        .then(() => {
          this.initEditorState(this.productId)
        })
        .catch((error) => {
          console.log('error saving box', error)
        })
        .finally(() => {
          this.setSpinnerState(false)
        })
    },
    temporalText: function (val) {
      val = val.split('\n').slice(0, 6).join('\n')

      if (!this.product.notext) {
        this.product.text = val
      }
    },
    'product.font'() {
      this.fontFamilyMenuActive = false
    },
  },
  methods: {
    ...mapActions(['updateOrder']),
    ...mapMutations(['setSpinnerState', 'updateProductAmount']),
    ...mapGetters({
      getOrderData: 'getOrderData',
    }),
    onResize() {
      if (this.$refs.fazerEditorRefWidth) {
        let editor = this.$refs.fazerEditorRefWidth
        let size = getSize(editor) // uncomment this to enable zoom support
        this.editorScale = size[0] / this.marianneEditorWidth
      }
      this.handleTextPreviewResize()
    },
    async loadTextures() {
      let self = this
      return self.$refs.customCandyBoxCanvas.draw().then(() => {
        // TODO check the resolution
        self.backAsImageUrl = self.$refs.customCandyBoxCanvas.$refs.frontCanvas.toDataURL('image/jpeg', 0.9)
        self.product.back_side_picture = self.$refs.customCandyBoxCanvas.$refs.titleBoxCanvas.toDataURL(
          'image/jpeg',
          0.9
        )
        self.textAsImageUrl = self.$refs.customCandyBoxCanvas.$refs.backCanvas.toDataURL('image/jpeg', 0.9)
        self.product.front_side_picture = self.$refs.customCandyBoxCanvas.$refs.textBoxCanvas.toDataURL(
          'image/jpeg',
          0.9
        )
      })
      .catch((error) => {
          console.log('textures couldnt be loaded', error)
      })
    },
    previewModal() {
      this.threeDeeRenderReady = false
      // take snapshot of image and text as it is and show 3d preview
      let self = this
      this.setSpinnerState(true)
      this.loadTextures()
        .then(() => {
          return self.$refs.previewModal.showModal()
        })
        .then((amount, ...args) => {
          console.log('3d preview closed', args)
        })
        .catch((error) => {
          console.log('3d preview closed', error)
        })
    },
    initEditorState(productId) {
      let product = this.$store.getters.getProductById(productId)
      this.product = product
      if (this.product.flavor == null) {
        this.product.flavor = 5
      } else {
        this.product.flavor = this.product.flavor.id
      }
      this.temporalText = this.product.text
    },
    result(output) {
      this.cropped = output
    },
    increaseAmount() {
      this.product.amount += 1
      this.updateProductAmount({ product_id: this.product.id, amount: this.product.amount })
    },
    decreaseAmount() {
      if (this.product.amount > 1) {
        this.product.amount -= 1
      }
      this.updateProductAmount({ product_id: this.product.id, amount: this.product.amount })
    },
    changedAmount() {
      this.updateProductAmount({ product_id: this.product.id, amount: this.product.amount })
    },
    changeFont() {
      this.product.font = this.tmpCandyBoxFont
      this.fontFamilyMenuActive = false
    },
    saveBox(productObject) {
      this.titleEmpty = false
      this.textEmpty = false
      this.signatureEmpty = false
      let scrollToError = ''
      if (this.product.title == '') {
        this.titleEmpty = true
        scrollToError = 'text-editor-title'
      }
      if (this.product.text == '' && !this.product.notext) {
        this.textEmpty = true
        if (!scrollToError) scrollToError = 'text-editor-main'
      }
      if (this.product.signature == '' && !this.product.nosignature) {
        if (!scrollToError) scrollToError = 'text-editor-signature'
        this.signatureEmpty = true
      }
      if (this.titleEmpty || this.signatureEmpty || this.textEmpty) {
        // return rejected promise in case product is not ready
        this.setSpinnerState(false)
        if(scrollToError) {
          document.getElementById(`${scrollToError}-desktop`).scrollIntoView({ block: "center", behavior: "smooth" });
          document.getElementById(`${scrollToError}-mobile`).scrollIntoView({ block: "center", behavior: "smooth" });
        }        
        return Promise.reject('Check product')
      } else {
        // this will send updated images to backend
        return this.loadTextures().then(() => {
          if (productObject == null) productObject = this.product
          productObject['draft'] = false
          productObject['type'] = 'ALL_TO_SAME_ADDRESS'
          productObject['font'] = this.product.font
          productObject['background_color'] = this.product.background_color
          if (productObject['background_color'] == null) {
            productObject['background_color'] = '#44BDC7'
          }
          return this.updateOrder({'products': [productObject]})
        })
      }
    },
    handleTextPreviewResize: debounce(function () {
      let size = document.querySelector('.text-preview')?.offsetWidth
      this.element_zoom = size / 900
    }, 300),
    inputTextarea(event) {
      this.product.text = event
    },
    inputTitle(event) {
      this.product.title = event
    },
    inputSignature(event) {
      this.product.signature = event
    },
    toggleCard() {
      this.isCover = false
    },
    toggleBox() {
      this.isCover = true
    },
    toggleNoText() {
      this.product.notext = !this.product.notext
      if (this.product.notext) {
        this.product.text = ''
      } else {
        this.product.text = this.temporalText
      }
    },
    toggleNoSignature() {
      this.product.nosignature = !this.product.nosignature
      if (this.product.nosignature) {
        this.product.signature = ''
      } else {
        this.product.signature = this.temporalText
      }
    },
    updateBgColor(eventData) {
      this.tmpBgColor = eventData.cssColor
      this.product.background_color = eventData.cssColor
    },
    changeBgColor() {
      // render the editor background color
      /*
      const activeObject = this.canvas.getActiveObject()
      if (activeObject && activeObject.type === 'i-text') {
        activeObject.set("fill", this.fontColor)
        this.canvas.requestRenderAll()
      }*/
      this.product.background_color = this.tmpBgColor
      this.showBgColorPicker = false
    },
    changeLogo() {
      this.updateOrder({'products': [this.product]}).then(() => {
        this.$refs.logoEditorModal
          .showModal()
          .then(() => {
            this.toggleBox()
            this.initEditorState(this.productId)
          })
          .catch(() => {
            console.log('error')
          })
      })
    },
    openRemoveModalLogo(box) {
      if (this.product.original_logo_url) {
        this.$refs.deleteModalLogo
          .showModal()
          .then(() => {
            this.setSpinnerState(true)
            let boxData = {
              id: this.productId,
              logo: '',
              logo_croppie_points: null,
              logo_croppie_zoom: null,
              logo_croppie_orientation: null,
              original_logo: '',
            }
            this.$store
              .dispatch('partialUpdateCustomCandyBox', boxData)
              .then(() => {
                this.initEditorState(this.productId)
                this.setSpinnerState(false)
              })
              .catch(() => {
                this.setSpinnerState(false)
              })
          })
          .catch(() => {})
      }
    },
    threeDeerenderReady() {
      this.threeDeeRenderReady = true
      this.setSpinnerState(false)
    },
    handleKeyDownFontMenu(event) {
      const lastMenuItem = this.$refs.lastFontMenuItem
      if (event.key === 'Tab') {
        if (document.activeElement === lastMenuItem && !event.shiftKey) { // last tab element
          this.fontFamilyMenuActive = false
        }
      } else if (event.key === 'Escape') {
        this.fontFamilyMenuActive = false
      }
    },
    openFontMenu() {
      if (!this.fontFamilyMenuActive) {
        this.fontFamilyMenuActive = true
        this.$nextTick(() => {
          this.$refs.firstFontMenuItem.focus()
        })
      } else {
        this.fontFamilyMenuActive = false
      }
    }
  },
}
</script>

<style scoped lang="scss">
@import '../assets/sass/mybulma';

$total-width: 1110px;
$original-width: 4 00px;
$switch-background: #726f6f;
$switch-background-active: #726f6f;

.editor {
  @media (max-width: 500px) {
    margin-top: 20px;
  }

  span {
    font-size: 18px;

    @media (max-width: 400px) {
      font-size: 12px;
    }

    font-family: gill-sans-nova;
    color: $primary;
  }

  label {
    font-size: 18px;
    font-family: gill-sans-nova;
    color: $primary;
  }

  .switch[type='checkbox'].is-rounded + label::before {
    border: 1px solid grey;
  }

  label.togglebold {
    color: $primary;
    font-weight: bold;
  }

  .right-label {
    padding-left: 4rem !important;
    margin-left: 1rem;
  }

  .amount-options {
    .svg-wrapper {
      display: inline-block;

      svg {
        font-size: 25px;
        top: 3px;
        position: relative;
        color: $primary;
      }

      svg:hover {
        cursor: pointer;
        color: $primary;
      }
    }

    span {
      width: 70px;
      display: inline-block;
      border: 1px solid gray;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  #editor-title {
    text-align: left;
  }

  .is-checkradio[type='radio']:hover:not([disabled]) + label::before {
    border: 0.1rem solid $secondary !important;
  }

  .is-checkradio[type='radio']:checked + label::after {
    background-color: $secondary !important;

    &:hover {
      border: 0.1rem solid $secondary !important;
    }
  }
}

.level-right {
  @media screen and (max-width: 500px) {
    font-size: 10px;
  }
}

.level-item p {
  color: $primary;
  font-family: gill-sans-nova;
  margin-top: 33px;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.amount-input-wrapper {
  width: 80px;
  margin-left: 10px;
  margin-right: 10px;
}

#title-box-preview {
  font-size: 47px;
  color: #222;
  font-weight: 500;
  font-family: 'Felt That';

  @media screen and (max-width: 600px) {
    font-size: 15px !important;
  }
}

.checkradio-label {
  font-size: 18px !important;

  @media screen and (max-width: 500px) {
    font-size: 12px !important;
  }
}

.editor-top-span {
  border-bottom: 1px solid $primary;
  padding-bottom: 10px;
}

.editor-span-left {
  padding-left: 20px;
  padding-bottom: 20px;

  @media screen and (max-width: 768px) {
    padding-left: 10px;
    padding-bottom: 0px;
  }
}

.border-bottom {
  margin-left: 1px;
  margin-right: 1px;
  border-bottom: 1px solid $primary;
}

.border-right {
  border-right: 1px solid $primary;
}

// Front view css
#front-editor-wrapper {

  aspect-ratio: 18/13.5;
  // @media screen and (min-width: 768px) {
  //   height: 800px;
  // }

  border-bottom: 1px solid #c69b67;

  #front-text-box-wrapper-preview {
    width: 55%;
    height: 81%;
    background-color: white;
  }

  .logo-upload {
    margin-top: 9%;
    margin-bottom: 9%;
    height: 45%;
    width: 80%;
    border: 1px dashed red;
    .logo-icon {
      background-image: url('../../public/imgs/logoupload.svg');
      height: 60px;
      width: 80px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .logo-uploaded-wrapper {
      margin-top: 9%;
      margin-bottom: 9%;
      height: 40%;
      width: 80%;    
    .logo-uploaded {
      height: 100%;
      width: 100%;
    }
  }
}

#back-editor-wrapper {
  // @media screen and (min-width: 768px) {
  //   height: 800px;
  // }
  // display: flex;
  // justify-content: center;
  // align-items: center;
  aspect-ratio: 27/21;

  .back-editor-wrapper-content {
    height: 100%;
    // height: 450px;
    // max-width: 590px;
    //margin: auto;
    //flex-wrap: wrap;

    // @media screen and (max-width: 768px) {
    //   height: 300px;
    // }
    // @media screen and (max-width: 500px) {
    //   height: 200px;
    // }
    .text-preview-content {
      width: 85%;
      height: 62%;
      margin-top: 12%;
      margin-left: 7.5%;
      margin-right: 7.5%;
      font-family: 'Karl Fazer';

      // @media screen and (max-width: 1700px) {
      //   height: 40%;
      // }
      div {
        width: 100%;
        height: 100%;

        .first-para {
          padding-top: 5%;
          height: 50%;
          //display: flex;
          align-items: end;
          font-size: 20px;
          font-weight: 500;
          color: #222;
          justify-content: center;
          line-height: 35px !important;

          @media screen and (max-width: 1703px) {
            font-size: 17px !important;
            line-height: 28px !important;
          }

          @media screen and (max-width: 1215px) {
            font-size: 15px !important;
            line-height: 23px !important;
          }
          @media screen and (max-width: 1023px) {
            font-size: 17px !important;
            line-height: 28px !important;
          }                  
          @media screen and (max-width: 900px) {
            font-size: 14px !important;
            line-height: 21px !important;
          }
          @media screen and (max-width: 600px) {
            font-size: 12px !important;
            line-height: 17px !important;
          } 
          @media screen and (max-width: 400px) {
            font-size: 8px !important;
            line-height: 12px !important;
          }                   
        }

        .second-para {
          height: 50%;
          margin-top: 14%;
          // display: flex;
          // align-items: end;
          font-size: 30px !important;
          font-weight: 500;
          // padding-bottom: 10px;
          color: #222;
          //justify-content: center;
          
          @media screen and (max-width: 1703px) {
            font-size: 27px !important;
          }
          @media screen and (max-width: 1215px) {
            font-size: 24px !important;
          }           
          @media screen and (max-width: 1023px) {
            font-size: 24px !important;
          }
          @media screen and (max-width: 900px) {
            font-size: 22px !important;;
          }
          @media screen and (max-width: 600px) {
            font-size: 17px !important;;
          }
          @media screen and (max-width: 400px) {
            font-size: 12px !important;;
          }                 
        }
      }
    }
  }
}

// .editor-wrapper {
//   @media screen and (max-width: 1700px) {
//     height: 930px;
//   }

//   @media screen and (max-width: 1240px) {
//     height: 620px;
//   }

//   @media screen and (max-width: 991px) {
//     height: 680px;
//   }

//   @media screen and (max-width: 768px) {
//     height: 575px;
//   }

//   @media screen and (max-width: 600px) {
//     height: 450px;
//   }

//   @media screen and (max-width: 520px) {
//     height: 380px;
//   }

//   @media screen and (max-width: 420px) {
//     height: 344px;
//   }
// }

.add-logo:hover {
  cursor: pointer;
}

.add-logo {
  cursor: pointer;
  color: $primary;
  svg {
    font-size: 35px;
  }
  svg:hover {
    cursor: pointer;
  }
  p {
    color: $primary;
    font-family: gill-sans-nova;
  }
  p:hover {
    text-decoration: underline;
  }
}

.remove-logo:hover {
  cursor: pointer;
}

.remove-logo {
  cursor: pointer;
  color: $primary;

  svg {
    font-size: 35px;

    @media screen and (max-width: 500px) {
      font-size: 25px;
    }
  }

  svg:hover {
    cursor: pointer;
  }

  p {
    color: $primary;
    font-family: gill-sans-nova;
  }

  p:hover {
    text-decoration: underline;
  }
}

label.right-label {
  font-size: 18px !important;
}

#flavour-selector {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin-bottom: 40px;

  label {
    p {
      font-size: 18px;
      font-weight: bold;
      color: #15256b;
    }
  }

  img {
    padding: 20px;
  }

  /* HIDE RADIO */
  [type='radio'] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* IMAGE STYLES */
  [type='radio'] + img {
    cursor: pointer;
  }

  /* CHECKED STYLES */
  [type='radio']:checked + img {
    background-color: #ebeff5;
  }
  label.selected {
    background-color: #ebeff5;
    p {
      background-color: #ebeff5;
    }
  }
}

.color-span {
  height: 50px;
  width: 50px;
}

.bg-color-picker-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border: 1px solid gray;
  background-color: white;
  z-index: 99;
}

.font-selection {
  position: absolute;
  right: 12px;
  top: -28px;

  .dropdown-content {
    padding: 20px;
  }

  @media screen and (max-width: 500px) {
    top: -3px;
    right: 0px;
  }

  a {
    font-size: 18px;
    text-align: left;
  }

  span.is-medium-important {
    font-size: 1.8rem !important;
  }

  .font-selection-buttons-wrapper {
    display: flex;
    justify-content: center;
    button {
      span {
        color: white;
      }
    }
  }
}

#text-box-wrapper-preview {
  width: 400px;
  height: 555px;
  background-color: white;
  @media screen and (max-width: 767px) {
    height: 400px;
  }
  @media screen and (max-width: 600px) {
    height: 200px;
    width: 200px;
  }
}

.logo-upload {
  margin-top: 50px;
  margin-bottom: 50px;
  height: 170px;
  width: 300px;
  border: 1px dashed red;
  .logo-icon {
    background-image: url('../../public/imgs/logoupload.svg');
    height: 60px;
    width: 80px;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: 600px) {
    margin-top: 25px;
    margin-bottom: 25px;
    height: 85px;
    width: 150px;
  }
}

.fa-disabled {
  color: lightgray !important;
  p {
    color: lightgray !important;
  }
}

.red-color {
  color: $fazer-red !important;
}

</style>
